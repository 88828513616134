import Field from "./Field";
import Form from "./Form";

declare global {
    interface Window {
        _hideLabel: string;
        _showLabel: string;
        _cdn: string;
    }
}

/**
 * @author Andrea Moraglia
 */
export default class PasswordField extends Field {

    constructor($input: JQuery, form: Form, type: string = "string") {
        super($input, form, type);

        // eslint-disable-next-line max-len
        const $passwordToggle = $(`<img class="password-toggle pointer" src="${window["_cdn"]}/_ui/img/Icons/View-psw.svg" alt="view password" height="20px" width="20px"/>`);

        $passwordToggle.click(() => {
            if ($input.attr("type") === "password") {
                $input.attr("type", "text");
                $passwordToggle.attr("src", `${window["_cdn"]}/_ui/img/Icons/Hide-psw.svg`)
            } else {
                $input.attr("type", "password");
                $passwordToggle.attr("src", `${window["_cdn"]}/_ui/img/Icons/View-psw.svg`)
            }
        });

        $input.after($passwordToggle);
    }

    getValue(): Record<string, unknown> {
        return super.processValue(this.selector.val());
    }

    setValue(value: any): void {
        this.selector.val(value).trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this.selector.val() ? false : super.processValid(this.getValue());
    }

    clear(): void {
        this.setValue("");
    }
}