// eslint-disable-next-line max-len
export const SVG = `<svg viewBox="0 0 128 128" class="icon-spin"><circle class="path" cx="64" cy="64" r="42" fill="none" stroke-width="9"></circle></svg>`;

export function addSpinner(btn: HTMLElement | JQuery, append: boolean = false): void {

    if (!btn)
        return;

    const $btn: JQuery = $(btn);
    const $svg: JQuery = $(SVG);

    $btn.attr("disabled", "disabled");

    const $icon: JQuery = $btn.find(".icon");

    if (!$icon.length) {
        $svg.addClass(`icon icon-md ${append ? "ml-5" : "mr-5"}`);
        append ? $btn.append($svg): $btn.prepend($svg);
    } else {
        $btn.data("icon-prev", $icon);
        $svg.addClass($icon.attr("class") || "");
        $icon.replaceWith($svg);
    }
}

export function removeSpinner(btn: HTMLElement | JQuery): void {

    if (!btn)
        return;

    const $btn: JQuery = $(btn);

    $btn.removeAttr("disabled");

    const $svg: JQuery = $btn.find(".icon-spin");

    const prev = $btn.data("icon-prev");

    if (prev) {
        $svg.replaceWith(prev);
    } else
        $svg.remove();
}

$(() => {
    const dataSpinners = [].slice.call(document.querySelectorAll("[data-spinner]"));

    dataSpinners.forEach((entry: HTMLElement) => {
        const $entry: JQuery = $(entry);

        $entry.on("click", () => {

            if ($entry[0].hasAttribute("disabled"))
                return;

            addSpinner($entry);

            setTimeout(() => {
                removeSpinner($entry);
            }, 30000);
        });
    });
});