import Field from "./Field";
import "../select";
import Form from "./Form";

declare  global {
    interface Window {
        [key: string] : any;
    }
}
/**
 * @author Andrea Moraglia
 */
export default class SelectField extends Field {

    constructor($input: JQuery, form: Form, type: string = "string") {
        super($input, form, type);

        if ($input.data("link")) {

            const $link = this.form.getSelector().find($input.data("link"));

            if ($link.length) {
                $input.change(() => {

                    const $placeholder = $link.find("option[value=\"\"]");

                    const $option = $input.find("option:selected");

                    const list = window[$option.data("list")];

                    if (!list)
                        return;

                    $link.empty();

                    if ($placeholder.length)
                        $link.append($placeholder);

                    for (let i = 0; i < list.length; i++) {
                        const option: Record<string, any> = list[i];

                        const $option = $(`<option value="${option["id"]}">${option["label"]}</option>`);

                        const optionAttrs: Record<string, string> = option["attr"];

                        if (optionAttrs)
                            Object.keys(optionAttrs).forEach((key: string) => {
                                $option.attr(key, optionAttrs[key]);
                            });

                        $link.append($option);
                    }

                });
            }
        }

        $input.change(() => {
            const $option = $input.find("option:selected");

            if ($option.data("hide")) {
                const $target = $($option.data("hide"));

                $target.attr("data-hidden", "true").hide();

                $input.one("change", () => {

                    const $cOption = $input.find("option:selected");

                    if ($cOption.data("hide") === $option.data("hide"))
                        return;

                    const $cTarget = $($cOption.data("hide"));

                    // Restore only field that are not in the new option hide data-attibute
                    const $nTarget = $target.not($cTarget);

                    $nTarget.removeAttr("data-hidden").show();
                });
            }

            if ($option.data("show")) {
                const $target = $($option.data("show"));

                $target.removeAttr("data-hidden").show();

                $input.one("change", () => {

                    const $cOption = $input.find("option:selected");

                    if ($cOption.data("show") === $option.data("show"))
                        return;

                    const $cTarget = $($cOption.data("show"));

                    // Restore only field that are not in the new option hide data-attibute
                    const $nTarget = $target.not($cTarget);

                    $nTarget.attr("data-hidden", "true").hide();
                });
            }

            if ($option.data("requires")) {

                const fieldName = $option.data("requires");

                this.form.getField(fieldName)!.required = true;

                $input.one("change", () => {

                    const $cOption = $input.find("option:selected");

                    if ($cOption.data("requires") === $option.data("requires"))
                        return;

                    this.form.getField(fieldName)!.required = false;
                });
            }
        });

        // Andrea M: force loading data hide option when start with data-hide default value
        const $option = $input.find("option:selected");

        if ($option.data("hide") || $option.data("show"))
            $input.trigger("change");

    }

    getValue(): Record<string, unknown> {
        const $option = this.selector.find("option:selected");
        const value = $option.data("multiple-value") ? $option.data("multiple-value") : this.selector.val();
        return super.processValue(value);
    }

    setValue(value: any): void {
        this.selector.val(value !== null ? String(value) : "").trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this.selector.val() ? false : super.processValid(this.getValue());
    }

    clear(): void {
        this.selector.prop("selectedIndex", 0);
        this.selector.trigger("change");
    }
}