import "../../style/components/_popover.scss";
import "../../style/components/_tooltip.scss";
import "../../style/components/_transitions.scss";

// import "@wikicasa/common/modal";

/*
import {
    doLogin,
    doLogout,
    passwordRecovery,
    signinPublicUser,
    resendConfirmationEmail, doLoginSso
} from "./login";
import Form from "@wikicasa/common/form/Form";

 */

import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/tooltip";

/*
import {decodeCookie, encodeCookie, IExtendJQuery} from "@wikicasa/common/common";

declare const _baseURL: string;
declare const google: Record<string, any>;

let $publicUserMenuButton;
let $publicUserMenu: JQuery;

let $loginModal;

let $formLogin: JQuery;
let $formSignin: JQuery;
let $formPasswordRecovery: JQuery;

let $googleSso: JQuery;
let $facebookSso: JQuery;

let $signinModal: JQuery;
let $passwordRecoveryModal: JQuery;
let $emailConfirmModal: JQuery;
let $passwordRecoverySentModal: JQuery;

const SSO_REDIRECT_URI = window.location.origin;
const RESET_PASSWORD_LINK = `/ws/recupero-password`;
// eslint-disable-next-line max-len
const GOOGLE_SSO_LINK = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&redirect_uri=${SSO_REDIRECT_URI}/rest/sso/google&client_id=150725646086-0doo80vlsenp
ekc47k882ki3ldp6ol54.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/userinfo.email&access_type=offline&prompt=consent`;
// eslint-disable-next-line max-len
const FACEBOOK_SSO_LINK = `https://www.facebook.com/v6.0/dialog/oauth?client_id=595028487893780&redirect_uri=${SSO_REDIRECT_URI}/rest/sso/facebook&display=popup&scope=email`;
 */

$(() => {

    /*
    if (window["_logged"]) {
        $publicUserMenuButton = $("#public-user-menu-button");
        $publicUserMenu = $("#public-user-menu");
        $emailConfirmModal = $("#email-confirm-modal");

        $publicUserMenu.tooltip({selector: "[data-toggle=tooltip]"});

        $publicUserMenuButton.on("mouseenter", () => {
            $publicUserMenu.show();
        });

        $publicUserMenu.on("mouseleave", () => {
            $publicUserMenu.hide();
        });

        $publicUserMenu.on("click", "a", () => {
            $publicUserMenu.hide();
        });

        $("#mobile-public-logout, #public-logout").on("click", () => {
            encodeCookie("googleOnetapCooldown", {time: Date.now()});
            doLogout().done(() => location.reload());
        });

        $("#verify-button, #verify-button-mobile").on("click", () => {
            resendConfirmationEmail(window["_email"]).done(() => {
                $emailConfirmModal.modal("show");
            });
        })
    } else {
        $loginModal = $("#login-modal");
        $signinModal = $("#signin-modal");
        $passwordRecoveryModal = $("#password-recovery-modal");
        $emailConfirmModal = $("#email-confirm-modal");
        $passwordRecoverySentModal = $("#password-recovery-sent-modal");

        $formLogin = $loginModal.find("form");
        $formSignin = $signinModal.find("form");
        $formPasswordRecovery = $passwordRecoveryModal.find("form");

        $googleSso = $("#google-sso", $loginModal);
        $facebookSso = $("#facebook-sso", $loginModal);

        const formLogin = new Form($formLogin);
        const formSignin = new Form($formSignin, {discardEmptyValues: true});
        const formPasswordRecovery = new Form($formPasswordRecovery);

        formLogin.onSubmit((e: JQuery.Event) => {
            e.preventDefault();

            if (!formLogin.validate(true)) {
                formLogin.clearSubmit();
                return;
            }

            const data = $formLogin.serialize().split("--ignore").join("");

            doLogin(data).then(() => {
                window["_changePasswordToken"] ? window.location.replace(location.origin) : location.reload();
            }).catch((error: JQuery.jqXHR) => {
                console.error(error);

                if ((error.status === 400) || (error.status === 401)) {
                    $formLogin.find(".form-error").text("Credenziali errate");
                } else {
                    $formLogin.find(".form-error").text("Si è verificato un problema con il login");
                }

                formLogin.clearSubmit();
            });
        });

        formSignin.onSubmit((e: JQuery.Event) => {
            e.preventDefault();

            if (!formSignin.validate(true)) {
                formSignin.clearSubmit();
                return;
            }

            const publicUser = formSignin.getValues();

            signinPublicUser(publicUser).done(() => {
                $signinModal.modal("hide");
                $emailConfirmModal.modal("show");
                doLogin({"username": publicUser.email, "password": publicUser.password});
            }).fail((error: JQuery.jqXHR) => {
                console.error(error);
                formSignin.clearSubmit();
                $formSignin.find(".form-error").text("Si è verificato un errore");
            });
        });

        formPasswordRecovery.onSubmit((e: JQuery.Event) => {
            e.preventDefault();

            if (!formPasswordRecovery.validate(true)) {
                formPasswordRecovery.clearSubmit();
                return;
            }

            const email = formPasswordRecovery.getValues();
            const data = $.extend({}, email, {link: RESET_PASSWORD_LINK});

            passwordRecovery(data).done(() => {
                $passwordRecoveryModal.modal("hide");
                $passwordRecoverySentModal.modal("show");
            }).fail((error: any) => {

                if ((error.status === 400) || (error.status === 401)) {
                    formPasswordRecovery.getSelector().find(".form-error").show();
                } else
                    console.log(error);
            }).always(() => {
                formPasswordRecovery.clearSubmit();
            });
        });

        $emailConfirmModal.on("hidden.bs.modal", () => {
            location.reload();
        });

        $googleSso.on("click", () => {
            window.open(
                GOOGLE_SSO_LINK,
                "GoogleSso",
                "status=no,menubar=no,toolbar=no,width=800,height=600,top=100,left=100"
            );
        });

        $facebookSso.on("click", () => {
            window.open(
                FACEBOOK_SSO_LINK,
                "FacebookSso",
                "status=no,menubar=no,toolbar=no,width=800,height=600,top=100,left=100"
            );
        });

        const field = formSignin.getField("password");

        if (field) {
            (field.selector as IExtendJQuery).once("click focus", () => field.selector.popover("show"));
            (field.selector as IExtendJQuery).once("blur focusout", () => field.selector.popover("hide"));
        }

        window["googleOneTapLogin"] = (response: Record<string, unknown>): void => {
            $.ajax({
                type: "GET",
                url: `/rest/sso/googleOneTap?credential=${response["credential"]}`
            }).done((response: JQuery.jqXHR, status: unknown, xhr: JQuery.jqXHR) => {
                const rid: string = xhr.getResponseHeader("rid") ?? "";
                if (rid) {
                    window.open(
                        `${window["_baseURL"]}/ws/loginProvider?rid=${rid}`,
                        "GoogleOneTap",
                        "status=no,menubar=no,toolbar=no,width=800,height=600,top=100,left=100"
                    );
                } else {
                    doLoginSso().then(() => {
                        window.location.reload();
                    })
                }
            }).fail((error: JQuery.jqXHR) => console.error(error));
        }
        window["onGoogleLibraryLoad"] = (): void => {
            google["accounts"].id.initialize({
                "client_id": "150725646086-0doo80vlsenpekc47k882ki3ldp6ol54",
                "callback": window["googleOneTapLogin"],
                "cancel_on_tap_outside": false
            });
            const googleCooldown = decodeCookie("googleOnetapCooldown");
            if (!googleCooldown || (googleCooldown && (Date.now() - googleCooldown["time"] > 3600000))) {
                google["accounts"].id.prompt();
            }
        };
    }

     */
});