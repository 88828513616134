/* Styles */
import "../style/base/_common.scss";
import "../style/components/_utilities.scss";
import "../style/components/_transitions.scss";
import "../style/components/_grid.scss";
import "../style/components/_nav.scss";
import "../style/components/_modal.scss";
import "../style/components/_breadcrumb.scss";
import "../style/components/_buttons.scss";
import "../style/components/_images.scss";
import "../style/components/_forms.scss";
import "../style/components/_box.scss";
import "../style/components/_tooltip.scss";
import "../style/layout/_cookie.scss";
import "../style/layout/_map.scss";
import "../style/layout/_header.scss";
import "../style/layout/_footer.scss";
import "../style/layout/_filters.scss";
import "../style/vendor/_jquery-ui.scss";
import "../style/vendor/_siema.scss";
import "../style/pages/home.scss";
/* JS */
import "core-js/modules/es.array.iterator";

import "./components/menu";
import "intersection-observer";
import "@wikicasa/common/lazy";

import * as Common from "@wikicasa/common/common";
let Filters: any;

$(document).ready(function () {

    initFilters();

    //stickybits("#header", {
    //    useStickyClasses: true,
    //});

    $("#modal-unsubscribe").modal("show");

    if (window["_publicUserConfirmed"]) {
        $("#confirmed-mail-modal").modal("show");
    }

    if (window["_publicUserFailedConfirm"]) {
        $("#confirmed-fail-modal").modal("show");
    }

    if (window["_confirmedUsernameAlias"]) {
        $("#confirmed-usernamealias-modal").modal("show");
    }

    $(".news-row").on("click", ".insertion", function(event: JQuery.ClickEvent){
        event.stopPropagation();

        location.href=$(this).data("url");
    });
});

function initFilters(): void {

    Common.observe("#filters-form-main", (elem: HTMLElement, observer: IntersectionObserver) => {

        import(/* webpackChunkName: "chunks/filters" */ "./components/filters").then((module: any) => {

            Filters = module;

            Filters.init({
                map: "#filters-map",
                formMain: "#filters-form-main",
                formMobile: "#filters-form-mobile",
            });

            try {
                Filters.loadSearch(JSON.parse(window["_serializedSearchBean"]));
            } catch (e: unknown) {
                console.error(e);
            }

            //if (!window["_isAgencyCategory"]) {
            //    try {
            //        searchBean = JSON.parse(JSON.parse(<string>getCookie("saveSearch")));
            //    } catch {
            //        searchBean = {};
            //    }
            //} else searchBean = {};
            //
            //Filters.ready(() => Filters.loadSearch(searchBean));
        });

        observer.disconnect();
    });
}