import Field from "./Field";
import * as Datepicker from "../datepicker";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default class DateField extends Field {

    constructor($input: JQuery, form: Form, type: string = "date") {
        super($input, form, type);

        $input.removeAttr("type");

        const defDate = $input.data("default-date") ? new Date($input.data("default-date")) : new Date();

        defDate.setHours(0, 0, 0, 0);

        if ($input.data("link")) {

            Datepicker.init($input, {
                default: new Date(defDate.getTime() - 30 * 24 * 60 * 60 * 1000),
            });

            const $target = this.form.getSelector().find($input.data("link"));

            Datepicker.link($input, $target);

        } else {

            Datepicker.init($input, {
                default: defDate,
            });
        }
    }

    getValue(): Date {
        return super.processValue(this.selector.datepicker("getDate"));
    }

    setValue(value: Date): void {
        this.selector.datepicker("setDate", value).trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this.selector.val() ? false : super.processValid(this.getValue());
    }

   clear(): void{
        return;
   }
}