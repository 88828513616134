import {decodeXml, groupBy, renderModal} from "./common";

import "./modal";

const SELECT_CLASS = "form-control-select";
const MENU_CLASS = "form-control-menu";

const MINIMUM_SEARCH_BOX = 5;

//import SimpleBar from 'simplebar';
//import 'simplebar/dist/simplebar.css';

declare const _cdn: string;

interface Option {
    value: string;
    label: string;
    attr: NamedNodeMap;
    group: JQuery<HTMLOptGroupElement>;
}

/**
 * @author Andrea Moraglia
 */
class Select {

    get selector(): JQuery {
        return this._selector;
    }

    private readonly _selectClass: string;
    private readonly _selector: JQuery<HTMLElement>;
    private readonly _menuClass: string;
    private _document: JQuery<Document>;
    private _select: JQuery<HTMLElement>;
    private _label: JQuery<HTMLElement>;
    private _labelPanel: JQuery<HTMLElement>;
    private _panel: JQuery<HTMLElement>;
    private _menu: JQuery<HTMLElement>;
    private _searchBar: JQuery<HTMLElement>;
    private _searchBarInput: JQuery<HTMLElement>;
    private _count: number;
    private _opts: Array<Option>;

    constructor($selector: JQuery) {

        this._selector = $selector;
        this._selectClass = `${SELECT_CLASS}`;
        this._menuClass = `${MENU_CLASS} c-scrollbar`;

        this._document = $(document);

        this.build();
        this.render();
    }


    build(): void {

        const panelId = `${this._selector.attr("id")}_panel`;

        this._select = $(`<div class="${this._selector.attr("class")} ${this._selectClass}"></div>`);
        this._label = $(`<span class="form-control-label d-none d-sm-inline-block"></span>`);
        this._labelPanel = $(`<span class="form-control-label d-sm-none" data-toggle="modal" data-target="#$panelId"></span>`);
        this._menu = this._selector.siblings(".form-control-menu").length ? this._selector.siblings(".form-control-menu") : $(`<ul></ul>`);
        // eslint-disable-next-line max-len
        this._searchBar = $(`<li class="select-filter py-10 px-20"><input type="text" class="form-control pl-0" id="${panelId}-filter" placeholder="Filtra"/>
            <i class="icon icon-primary icon-sm icon-clear-search d-none" data-src="${_cdn}/_ui/img/Icons/Close.svg"></i></li>`);
        this._searchBarInput = $("input", this._searchBar);

        this._panel = renderModal(panelId, window._chooseOptionLabel, "modal-sm-none", this._menu);
        this._count = 0;
        this._opts = [];

        this._selector.data("select", this);
        this._menu.prop("class", this._menuClass + " border-blue-register-page");
        this._menu.empty();

        const _selector = this._selector;
        const _select = this._select;
        const _label = this._label;
        const _labelPanel = this._labelPanel;
        const _menu = this._menu;
        const _searchBar = this._searchBar;
        const _searchBarInput = this._searchBarInput;
        //const _clearIcon = $(".icon-clear-search", _searchBar);
        const _opts = this._opts;

        const self = this;

        const $opts = _selector.find("option");

        for (let i = 0; i < $opts.length; i++) {
            const $opt = $($opts[i]);

            _opts.push({
                value: $opt.attr("value") ?? "",
                label: $opt.html(),
                attr: $opt.prop("attributes"),
                group: $opt.parent("optgroup")
            });
        }

        const optsMap = groupBy(_opts, (_opt: Option) => _opt.group?.attr("label") ?? "");

        Object.keys(optsMap).forEach((key: string) => {
            if (key) {

                const $group = optsMap[key][0].group;

                const $groupLi = $(`<li data-group><span class="d-block">${$group.attr("label")}</span><ul class="list-unstyled list-group"></ul></li>`);

                $groupLi.attr("class", $group.attr("class"));
                $groupLi.attr("style", $group.attr("style"));

                const $groupMenu = $groupLi.find("ul");

                optsMap[key].forEach((_opt: Option) => {
                    $groupMenu.append(this.renderLi(_opt));
                    this._count++;
                });

                _menu.append($groupLi);
            } else {
                optsMap[key].forEach((_opt: Option) => {
                    _menu.append(this.renderLi(_opt));
                    this._count++;
                });
            }
        });

        if (_selector[0].hasAttribute("data-sort")) {
            [..._menu[0].children]
                .filter(node => !node.hasAttribute("disabled"))
                .sort((nodeA, nodeB) => {
                    const a = nodeA.children.length ? nodeA.children.item(0)!.innerHTML : nodeA.innerHTML ?? "";
                    const b = nodeB.children.length ? nodeB.children.item(0)!.innerHTML : nodeB.innerHTML ?? "";
                    return a > b ? 1 : -1;
                })
                .forEach(node => {
                    _menu[0].appendChild(node);
                });
        }

        if (_selector.data("searchBox") && this._count > MINIMUM_SEARCH_BOX) {
            _menu.prepend(_searchBar);
            _menu.addClass("has-search-box");

            _searchBarInput.on("keyup", () => {
                _searchBar.find("svg").toggleClass("d-none", !_searchBarInput.val());
                if(!_searchBarInput.val()){
                    this.clear();
                    this.build();
                    this.render();
                }
                _menu.children().each((index, element) => {
                    const $el = $(element);
                    if (!$el.hasClass("select-filter")) {
                        $el.toggleClass("select-option-hidden", !$el.text().toLowerCase().includes(String(_searchBarInput.val()).toLowerCase()));
                    }
                });
            });

            _searchBar.on("click", "svg", () => {
                _searchBarInput.val("");
                _searchBarInput.trigger("keyup");
                this.clear();
                this.build();
                this.render();
            });
        }

        if (_opts.length) {

            const cLabel = _opts[0]?.label;

            _label.text(cLabel);
            _labelPanel.text(cLabel);
        }

        if (_selector.data("link"))
            _selector.on("click", "a", (e: JQuery.Event) => e.preventDefault());

        // Add mutation observer

        _selector.off("list-change.select");

        _selector.on("list-change.select", () => {
            self.clear();
            self.build();
            self.render();
        });

        _selector.off("change.select");

        _selector.on("change.select", () => {
            const $optionSelected = _selector.find("option:selected");

            const cLabel = $optionSelected.text();
            const labelGroup = $optionSelected.parent("optgroup")?.attr("label");

            const label = labelGroup ? `${labelGroup} - ${cLabel}` : cLabel;

            _label.text(label);
            _labelPanel.text(label);
        });

        _label.on("click", (e: JQuery.Event) => {
            e.stopPropagation();
            self.open(false);
        });

        _labelPanel.on("click", (e: JQuery.Event) => {
            e.stopPropagation();
            self.open(true);
        });

        _menu.find("li[data-group]").on("click", function (e: JQuery.Event) {
            e.stopPropagation();
            const $cGroup = $(this);
            _select.find("li[data-group]").not($cGroup).removeClass("opened");
            $cGroup.toggleClass("opened");
        });

        _menu.find("li[data-element]").on("click", function (e: JQuery.Event) {
            e.stopPropagation();
            _selector.val($(this).attr("data-value") ?? "").trigger("change");
            self.close();
        });

        this._panel?.on("click", (e: JQuery.Event) => e.stopPropagation())
    }

    renderLi(_opt: Option): JQuery {

        const $li = $(`<li data-element data-value="${_opt.value}">${_opt.label}</li>`);

        for (let k = 0; k < _opt.attr.length; k++) {
            const attr = _opt.attr[k];

            if (attr.name !== "value" && attr.name !== "data-template")
                $li.attr(attr.name, attr.value);

            if (attr.name === "data-template") {
                $li.html(decodeXml(attr.value));
            }
        }

        return $li;
    }

    render(): void {
        this._select.append(this._label);
        this._select.append(this._labelPanel);
        this._select.append(this._panel);
        this._selector.hide();

        this._selector.after(this._select);
        this._menu.hide();
        this._selector.trigger("change");

        //new SimpleBar(this._menu[0]);
    }

    clear(): void {
        this._select.remove();
        this._menu.remove();
        this._opts = [];
    }

    recreate(): void {
        this.clear();
        this.build();
        this.render();
    }

    open(panel: boolean = false): void {

        if ($(this._selector).attr("disabled")) return;

        document.body.classList.add("cselect-open");

        if (this._document.data("select"))
            this._document.data("select").close();

        if (panel)
            this._panel?.modal("show");

        this._menu.show();
        this._select.addClass("open");

        const $li = this._menu.find(`li[data-value="${this._selector.val()}"]`);

        $li.addClass("selected");
        $li.closest("li[data-group]").addClass("opened");
        $li.closest("li[data-group]").find("span").addClass("selected");

        this._searchBarInput.trigger("focus");


        this._document.on("keydown.select", (e: JQuery.KeyboardEventBase) => {
            if (!$(e.target).is("input"))
                this.handleKeys(e);
        });
        this._document.data("select", this);
    }

    close(): void {
        this._panel?.modal("hide");
        this._menu.hide();
        this._select.removeClass("open");
        this._selector.blur();
        document.body.classList.remove("cselect-open");

        this._menu.find("li, span").removeClass("selected opened");

        //this._document.on("keydown.select", (e: JQuery.KeyboardEventBase) => {
        //    if(!$(e.target).is("input"))
        //        this.handleKeys(e);
        //});
        //this._document.data("select", this);

        this._document.off("keydown.select");
        this._document.removeData("select");
    }

    handleKeys(e: JQuery.Event): void {

        e.preventDefault();

        const current = this._menu.find("li.selected");

        if (!e.key)
            return;

        const keyCode = parseInt(e.key);

        const prev = current.prev();
        const next = current.next();
        const char = String.fromCharCode(keyCode);
        const $options = this._menu.find("li");

        switch (true) {
            case  keyCode === 38: // up

                if (prev.length && !prev.is(":hidden")) {
                    current.removeClass("hover");
                    prev.addClass("hover");

                    this.scrollTo(prev);
                }

                break;
            case keyCode === 40: // down

                if (next.length && !next.is(":hidden")) {
                    current.removeClass("hover");
                    next.addClass("hover");

                    this.scrollTo(next);
                }

                break;
            case keyCode === 13: // enter
                current.trigger("click");
                break;
            case keyCode >= 65 && keyCode <= 90:

                for (let i = 0; i < $options.length; i++) {
                    const $option = $($options[i]);

                    if ($option.text().startsWith(char)) {
                        current.removeClass("hover");
                        $option.addClass("hover");

                        this.scrollTo($option);
                        break;
                    }
                }

                break;
        }
    }

    scrollTo(elem: JQuery): void {

        const elemOffset = elem.offset();
        const elemOffsetTop = elemOffset ? elemOffset.top : 0;
        const elemOffsetBottom = elemOffsetTop + (elem.outerHeight() || 0);

        const menuOffset = this._menu.offset();
        const menuOffsetTop = menuOffset ? menuOffset.top : 0;
        const menuOffsetBottom = menuOffsetTop + (this._menu.outerHeight() || 0);

        if (elemOffsetTop < menuOffsetTop) {
            this._menu.scrollTop((this._menu.scrollTop() || 0) - (menuOffsetTop - elemOffsetTop));
        }

        if (elemOffsetBottom > menuOffsetBottom)
            this._menu.scrollTop((this._menu.scrollTop() || 0) + (elemOffsetBottom - menuOffsetBottom));

    }
}

$(() => {

    const callback = function (mutationsList: Array<MutationRecord>): void {
        for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];

            if (mutation.type === "childList") {
                $(mutation.target).trigger("list-change.select");
                return;
            }

            if (mutation.type === "attributes" && mutation.attributeName === "class") {
                const s = $(mutation.target).data("select");
                s._select.prop("class", `${s._selector.attr("class")} ${s._selectClass}`);
            }
        }
    };

    const observer = new MutationObserver(callback);

    const selects = [].slice.call(document.querySelectorAll("select.form-control:not(.cselect)"));

    selects.forEach((select: HTMLElement) => {
        const s = new Select($(select));
        observer.observe(s.selector[0], {attributes: true, childList: true});

    });

    const $document = $(document);

    $document.on("click", () => {
        if ($document.data("select"))
            $document.data("select").close();

    });
});