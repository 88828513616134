import "jquery-ui/ui/widgets/autocomplete";
import Place from "./model/Place";
import IPlace from "./model/Place";
import {decodeEntity} from "./common";

declare global {
    interface Window {
        _cdn: string;
        _wholeMunicipalityLabel: string;
        _mapAreaDrawnLabel: string;
    }
}

export interface IAutocompleteInit {
    clear?: boolean;
    appendTo?: JQuery | null;
}

/**
 * @author Andrea Moraglia
 */
export default class {

    private _selector: JQuery;
    private _placeType: string;
    private _opts: IAutocompleteInit;
    private readonly _container: JQuery;
    private _zoneMenu: JQuery;
    private _edit: JQuery;
    private _enableZones: boolean;
    private _provinceId: number | undefined;

    constructor(selector: JQuery, opts: IAutocompleteInit = {
        clear: false,
        appendTo: null,
    }) {

        if (!selector.length)
            return;

        const self = this;

        // Prevent autocomplete
        selector.removeAttr("id");

        this._selector = selector;
        this._opts = opts;

        this._container = this._opts.appendTo && this._opts.appendTo.length ? this._opts.appendTo : this._selector.parent();

        this._placeType = this._selector.data("type") ?? "C";
        this._enableZones = this._placeType.includes("Z") || this._placeType.includes("L");

        this._provinceId = this._selector.data("provinceId");

        const options = {
            appendTo: this._container,
            delay: 0,
            minLength: 2,
            source: (request: any, response: any): void => {
                $.ajax({
                    dataType: "json",
                    contentType: "application/json",
                    type: "GET",
                    // eslint-disable-next-line max-len
                    url: "/ws/places?query=" + request.term + `&type=${this._placeType}` + `${this._provinceId ? `&provinceID=${this._provinceId}` : ""}`,
                    success: (data: Array<IPlace>) => {
                        response($.map(data, (place: IPlace) => ({
                            "label": place.displayName,
                            "value": place
                        })));
                    }
                });
            },
            change: (): void => {
                if (this._opts.clear && !this._selector.val())
                    this.clearAutocomplete();

                this.refreshLabel();
            },
            focus: (e: JQuery.Event): void => {
                e.preventDefault();
            }
        } as IAutocompleteInit;

        this._selector.on("focus", (e: any) => {
            e.stopPropagation();
            e.preventDefault();

            // Prevent focus trigger manually by JS
            if (!e.originalEvent)
                this._selector.blur();
        });

        //@ts-ignore
        this._selector.autocomplete(options);
        this._selector.attr("autocomplete", "nope");

        if (this._enableZones) {
            this._zoneMenu = $("<ul id=\"zone-list\" class=\"zone-list form-control-menu form-control-menu-multiple c-scrollbar\" style=\"display:none;\"></ul>");

            this._zoneMenu.appendTo(this._container);

            this._zoneMenu.on("zone.reload", () => {

                if (!this._selector.data("place"))
                    return;

                const zoneItems = this._zoneMenu.find(".zone-item.active");

                interface SubPlace {
                    id: string,
                    url: string
                    placeID: string,
                    title: string | undefined
                }

                const zones: Array<SubPlace> = [];
                let localities: Array<SubPlace> = [];

                for (let i = 0; i < zoneItems.length; i++) {
                    const zone = $(zoneItems[i]);
                    if (zone.data("locality"))
                        localities = [
                            {
                                id: zone.data("id"),
                                url: zone.data("url"),
                                placeID: zone.data("place-id"),
                                title: zone.attr("title")
                            }
                        ]
                    else
                        zones.push({
                            id: zone.data("id"),
                            url: zone.data("url"),
                            placeID: zone.data("place-id"),
                            title: zone.attr("title")
                        });
                }

                this._selector.data("zones", zones);
                this._selector.data("localities", localities);
                this._selector.trigger("zone.reload");

                let label = this._selector.data("place").displayName;

                if (zones.length + localities.length) {
                    // label = zones.length + localities.length == 1 ? `${label} - ${zoneItems[0].getAttribute("title")}`
                    //     : `${label} - ${zones.length + localities.length} zone`;
                    if (localities.length === 1)
                        label = `${label} - ${decodeEntity(window["_labels"].locality)} ${zoneItems[0].getAttribute("title")}`
                    else if (zones.length === 1)
                        label = `${label} - ${zoneItems[0].getAttribute("title")}`
                    else if (zones.length > 1)
                        label = `${label} - ${zones.length} ${decodeEntity(window["_labels"].zones)}`;

                    this._zoneMenu.find(".zone-cancel").removeClass("active");
                } else
                    this._zoneMenu.find(".zone-cancel").addClass("active");

                this._selector.data("label", label);
                this.refreshLabel();
            });

            this._selector.on("autocompleteopen", () => {
                this._zoneMenu.hide();
            });

            this._selector.on("search", () => {
                self._zoneMenu.empty();
                this.clearAutocomplete();
            })

            $(document).click(() => this._zoneMenu.hide());

            this._zoneMenu.click((e: JQuery.Event) => e.stopPropagation());

            this._zoneMenu.on("click", ".zone-item", function () {

                $(this).hasClass("radio-button-item") ?
                    //Removing the selection of the localities and zones (radioBtt mode - localities)
                    self._zoneMenu.find("li.zone-item.active").removeClass("active") :
                    //Removing the selection of the locations (checkbox mode - zones)
                    self._zoneMenu.find("li.radio-button-item.active").removeClass("active");

                $(this).toggleClass("active");
                self._zoneMenu.trigger("zone.reload");
            });

            this._zoneMenu.on("click", ".zone-cancel", () => {
                self._zoneMenu.find(".zone-item.active").removeClass("active");
                self._zoneMenu.hide().trigger("zone.reload");
            });
        }

        this._selector.on("autocompleteselect", (event: JQuery.Event, ui: any) => {

            event.preventDefault();

            if (!ui.item)
                return;

            if (ui.item.value.localityID) {
                ui.item.value.localities = [ui.item.value.localityID];
                if (typeof ui.item.value.displayName == "string")
                    ui.item.value.displayName = [ui.item.value.displayName.split("-")[0].trim()];
            }

            if (ui.item.value.zoneID) {
                ui.item.value.zones = [ui.item.value.zoneID];
                if (typeof ui.item.value.displayName == "string")
                    ui.item.value.displayName = [ui.item.value.displayName.split("-")[0].trim()];
            }

            if (ui.item.value.areaOfInterestID)
                ui.item.value.displayName = ui.item.value.cityName != null ?
                    `${ui.item.value.cityName} - ${ui.item.value.areaOfInterestName}`
                    : `${ui.item.value.areaOfInterestName}`

            this._selector.data("label", ui.item.value.displayName);
            this._selector.data("url", ui.item.value.urlPartName);
            this._selector.data("latitude", ui.item.value.latitude);
            this._selector.data("longitude", ui.item.value.longitude);
            this._selector.data("place", ui.item.value);
            this._selector.data("zones", []);
            this._selector.data("localities", []);
            this._selector.data("map", false);

            if (["I", "L", "Z"].includes(ui.item.value.placeType))
                this._selector.data("areaUrl", ui.item.value.zoneURL);
            else // City or Province
                this._selector.removeData("areaUrl");

            this._selector.addClass("has-value");

            this._selector.blur();

            this.refreshLabel();

            if (this._enableZones && ui.item.value.placeType !== "I")
                this.loadZones(!ui.auto, ui.item.value.zones, ui.item.value.localities);
        });

        this._selector.on("click", (e: JQuery.Event) => {
            e.stopPropagation();

            // Matteo C: chiude eventuali select aperte
            const $document = $(document);

            if ($document.data("select"))
                $document.data("select").close();

            $(this._selector).select();

            if (this._enableZones && this._zoneMenu.html() && !this._selector.data("map"))
                this.openZone(false);

            const windowScrollTop: number = $(window).scrollTop() || 0;
            const windowHeight: number = $(window).height() || 0;
            const selectorOffset: JQuery.Coordinates | undefined = this._selector.offset();
            const selectorOuterHeight: number = this._selector.outerHeight(true) || 0;

            const bottomPos = windowScrollTop + windowHeight;
            const bottom = bottomPos - ((selectorOffset ? selectorOffset.top : 0) + selectorOuterHeight);
            if (bottom < 50) {
                $("html, body").animate({
                    scrollTop: "+=120px"
                }, 500);
            }
        });

        this._edit = $(`<i data-src="${window._cdn}/_ui/img/Icons/Edit.svg" ` +
            `height="32px" width="32px" class="icon icon-md btn-edit pointer icon-primary" alt="Touch icon" style="display:none !important;"></i>`);

        this._selector.parent().on("click", ".btn-edit", (e: JQuery.Event) => {
            e.stopPropagation();
            return this._selector.click();
        });
        this._selector.after(this._edit);
    }

    load(place: Place): void {

        const label = place.displayName;

        // Andrea M: prevent change event to clear field if no val in input: 'change' event will be triggered
        // before 'autocompleteselect' event (unfortunately)
        this._selector.val(label);

        this._selector.trigger("autocompleteselect", {
            item: {
                label: label,
                value: place,
            },
            auto: true
        });
    }

    loadZones(display: boolean = false, zoneIds: Array<number> = [], localityIds: Array<number> = []): void {
        const self = this;

        self._zoneMenu.empty();

        const place = self._selector.data("place");

        if (!place || !place.cityID)
            return;

        $.ajax({
            dataType: "json",
            contentType: "application/json",
            type: "GET",
            url: `/ws/zones?cityID=${place.cityID}`,
            success: (zonesOrLocalities: Array<IPlace>) => {

                if (!zonesOrLocalities.length)
                    return;

                let html = `<li data-element class="zone-cancel border-bottom">${window._wholeMunicipalityLabel}</li>`;
                const createLILabelHTMLString = (text: string) => `<li class="select-group places-li-label" ">${text}</li>`;
                const urlChecker = (urlPartName: string) => {
                    const parts = urlPartName.split("_");
                    const cleanURL = parts.length === 2 ? parts[1] : parts[0];
                    return {parts, cleanURL};
                };

                let zonesListHTMLString = "";
                const localitiesList: IPlace[] = [];
                let isZoneListEmpty = true;

                for (const zone of zonesOrLocalities) {
                    const {parts, cleanURL} = urlChecker(zone.urlPartName);

                    if (parts) {
                        if (zone.zoneID) {
                            isZoneListEmpty = false;
                            zonesListHTMLString += `<li data-element class="zone-item ${zoneIds.indexOf(zone.zoneID) > -1 ? "active" : ""}" data-id="${zone.zoneID}" ` +
                                `data-place-id="${zone.placeID}" data-url="${cleanURL}" title="${zone.zoneName}">${zone.zoneName}</li>`;
                        } else if (zone.localityID)
                            localitiesList.push(zone);
                    }

                }

                //We will display both the labels when there are localities and zones per the selected city (e.g. Olbia)
                if (localitiesList.length > 0) {
                    //In case there are only localities and no zones, we want to show only the localities label (e.g. Melendugno)
                    if (!isZoneListEmpty)
                        html += createLILabelHTMLString(window["_labels"].zones);
                    html += zonesListHTMLString;
                    html += createLILabelHTMLString(window["_labels"].localities);
                } else
                    //In any other scenario we will just display the list of zones
                    html += zonesListHTMLString;

                for (const locality of localitiesList) {
                    const {parts, cleanURL} = urlChecker(locality.urlPartName);
                    if (parts) {
                        html += `<li data-element class="zone-item radio-button-item ${localityIds.indexOf(locality.localityID) > -1 ? "active" : ""}" ` +
                            `data-id="${locality.localityID}" data-place-id="${locality.placeID}" data-locality="true" ` +
                            `data-url="${cleanURL}" title="${locality.localityName}">${locality.localityName}</li>`;
                    }
                }

                self._zoneMenu.html(html);
                self._zoneMenu.trigger("zone.reload");

                if (display)
                    self.openZone(true);
            }
        });
    }

    clearAutocomplete(): void {

        this._selector.removeData("label");
        this._selector.removeData("url");
        this._selector.removeData("latitude");
        this._selector.removeData("longitude");

        this._selector.removeData("place");
        this._selector.removeData("zones");

        this._selector.removeClass("has-value");
    }

    refreshLabel(): void {
        const map = this._selector.data("map");
        const label = this._selector.data("label");

        this._selector.val(map ? window._mapAreaDrawnLabel : label);
        this._selector.trigger("label.refresh");
    }

    openZone(scroll: boolean = false): void {

        const selectorOffset: JQuery.Coordinates | undefined = this._selector.offset();
        const containerOffset: JQuery.Coordinates | undefined = this._container.offset();
        const selectorOuterHeight: number = this._selector.outerHeight() || 0;

        if (selectorOffset && containerOffset)
            this._zoneMenu.css("top", selectorOffset.top - containerOffset.top + selectorOuterHeight)
                .css("left", selectorOffset.left - containerOffset.left);

        this._zoneMenu.show();

        if (scroll) {
            const $selectedZone = this._zoneMenu.find(".zone-item.active").first();

            const zoneMenuScrollTop = this._zoneMenu.scrollTop() || 0;
            const selectedZoneOuterHeight = $selectedZone.outerHeight() || 0;

            if ($selectedZone.length) {
                this._zoneMenu.animate({
                    scrollTop: zoneMenuScrollTop + $selectedZone.position().top - (selectedZoneOuterHeight / 2)
                }, 750);
            }
        }

        this._selector.trigger("zone.open");
    }

    toggleMap(): void {
        //@ts-ignore
        this._selector.data("map", true);
        //@ts-ignore
        this._selector.autocomplete("option", "change").call(this);
    }

    toggleCity(): void {
        this._selector.data("map", false);
        //@ts-ignore
        this._selector.autocomplete("option", "change").call(this);
    }

    get place(): Place {
        return this._selector.data("place");
    }

    get zones(): Array<Place> {
        return this._selector.data("zones") ?? [];
    }

    get localities(): Array<Place> {
        return this._selector.data("localities") ?? [];
    }

    get areaUrl(): string {
        return this._selector.data("areaUrl") ?? "";
    }

    get enableZones(): boolean {
        return this._enableZones;
    }
}