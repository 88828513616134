import Field from "./Field";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default class RadioField extends Field {

    constructor($input: JQuery, form: Form, type: string = "string") {
        super($input, form, type);

        if ($input.data("show")) {

            this.form.getSelector().on("change", `input[name="${this.name}"]`, () => {

                const $target = this.form.getSelector().find($input.data("show"));

                if ($input.is(":checked"))
                    $target.attr("data-hidden", "false").show();
                else
                    $target.attr("data-hidden", "true").hide();
            });
        }

        if ($input.data("hide")) {

            this.form.getSelector().on("change", `input[name="${this.name}"]`, () => {

                const $target = this.form.getSelector().find($input.data("hide"));

                if (!$input.is(":checked"))
                    $target.attr("data-hidden", "false").show();
                else
                    $target.attr("data-hidden", "true").hide();
            });
        }
    }

    getValue(): Record<string, unknown> | undefined {
        const field = this.form.getFields().find((field: Field) => field.name === this.name && field.selector.is(":checked"));
        if (field) {
            return super.processValue(field.selector.val());
        }
    }

    setValue(value: any): void {
        const field = this.form.getFields().find((field: Field) => field.name === this.name && field.selector.val() === value);
        if (field)
            field.selector.prop("checked", true);
    }

    isValid(): boolean {
        return this.isRequired() && !this.form.getFields()
            .some((field: Field) => field.name === this.name && field.selector.is(":checked")) ? false : super.processValid(this.getValue());
    }

    clear(): void {
        this.setValue("");
    }
}