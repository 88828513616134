import "intersection-observer";
import "core-js/modules/es.array.iterator";

import {observe, replaceAll} from "./common";

const support: Record<string, boolean> = {};
const callbacks: Array<() => unknown> = [];

enum FEATURE {
    LOSSY = "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    LOSSLESS = "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    ALPHA = "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    ANIMATION = "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
}

checkWebP(FEATURE.LOSSY);

document.addEventListener("DOMContentLoaded", () => {

    checkSupport().then(() => {

        // Andrea M: don't use img.lazy due to iframe element lazy loading
        observe(".lazy", (lazyImage: HTMLImageElement) => lazyLoad(lazyImage),
            {rootMargin: "100px 0px"});
    });
});

function checkSupport(): Promise<unknown> {
    return new Promise((resolve: (...args: Array<unknown>) => unknown) => {

        if ("webp" in support)
            resolve();
        else
            callbacks.push(() => resolve());
    });
}

function checkWebP(feature: FEATURE): void {

    const img = new Image();

    img.onload = (): void => {
        support["webp"] = (img.width > 0) && (img.height > 0);
        callbacks.forEach((fn: () => unknown) => fn());
    };

    img.onerror = (): void => {
        support["webp"] = false;
        callbacks.forEach((fn: () => unknown) => fn());
    };

    img.src = "data:image/webp;base64," + feature;
}

export function lazyLoad(lazyImage: HTMLImageElement): Promise<unknown> {

    return new Promise((resolve: (...args: Array<unknown>) => unknown) => {
        let src = lazyImage.getAttribute("data-src") ?? "";
        let srcset = lazyImage.getAttribute("data-srcset") ?? "";
        //const sizes = lazyImage.getAttribute("sizes") ?? "";

        if (lazyImage.classList.contains("lazy-webp") && support["webp"]) {
            src = replaceAll(src, "jpg", "webp");
            srcset = replaceAll(srcset, "jpg", "webp");
        }

        if (lazyImage.classList.contains("lazy-bg")) {
            lazyImage.style.cssText = "display:none !important";

            const parent = lazyImage.parentNode as HTMLElement;

            lazyImage.classList.remove("lazy-bg");

            const update = function (): void {
                const src = typeof lazyImage.currentSrc !== "undefined" ? lazyImage.currentSrc : lazyImage.src;

                if (src !== parent.getAttribute("data-src")) {
                    parent.setAttribute("data-src", src);

                    parent.style.backgroundImage = "url(\"" + src + "\")";
                }
            };

            lazyImage.addEventListener("load", () => {
                update();
            });

            if (lazyImage.complete) {
                update();
            }
        }

        if (lazyImage.nodeName === "IFRAME") {
            lazyImage.srcset = srcset || "";
            lazyImage.src = src || "";
            lazyImage.classList.remove("lazy");

            resolve();

        } else {

            lazyImage.srcset = srcset || "";
            lazyImage.src = src || "";
            lazyImage.classList.remove("lazy");

            lazyImage.onload = (): void => {
                resolve();
            };
        }
    });
}

