import "intersection-observer";
import Cookies from "js-cookie";
import type {LatLng} from "leaflet";
import "./modal";
import Form from "./form/Form";
import * as Icons from "./icons";
import Leaflet_map from "./leaflet_map";
import RealEstateSearchBean, {searchBeanMap, searchBeanMapAgencyFilter} from "./model/RealEstateSearchBean";
import moment from "moment";

export const CONTRACT_TYPE_SALE = 0
export const CONTRACT_TYPE_RENT = 1
//Macro Typology
export const TYPE_SALE = 1;
export const TYPE_RENT = 2;
//export const TYPE_SALERENT = 3;
export const TYPE_AUCTION = 3;
export const TYPE_BARE_OWNERSHIP = 4;


export const MACROTYPOLOGY_RESIDENTIAL = 1;
export const MACROTYPOLOGY_BUSINESS = 2;
export const MACROTYPOLOGY_LAND = 3;
export const MACROTYPOLOGY_BUILDINGSITE = 4;
export const MACROTYPOLOGY_AGENCY = 5;
export const MACROTYPOLOGY_ACTIVITY = 6;
export const MACROTYPOLOGY_CONSTRUCTION_SITE = 8;

export const TYPOLOGY: Record<string, number> = {
    appartamento: 1,
    villa: 2,
    villaSchiera: 3,
    attico: 4,
    casaIndipendente: 5,
    rustico: 6,
    box: 7,
    ufficio: 8,
    negozio: 9,
    palazzinaUffici: 10,
    palazzinaCommerciale: 11,
    laboratorio: 12,
    magazzino: 13,
    capannone: 14,
    albergo: 15,
    aziendaAgricola: 18,
    loft: 19,
    palazzo: 20,
    bar: 22,
    ristorante: 24,
    garage: 74,
    pertinenze: 101,
    cameraStanza: 123,
    stanzaInVilla: 125,
    stanzaInAppartamento: 126,
    stanzaInCasaIndipendetente: 127,
    castello: 131,
    cascina: 132,
    casaColonica: 133,
    baita: 134,
    trullo: 136,
    casale: 137,
    dammuso: 138,
    nuraghe: 139,
    baglio: 141,
    maso: 140,
    rifugio: 142,
    sasso: 143,
    masseria: 144,
    torre: 145,
    dimoraStorica: 146,
    palazzoStorico: 147,
    abitazioneTipica: 148,
    immobileStorico: 149,
    porzioneDiCasa: 150,
    villaBifamiliare: 154,
    villaTrifamiliare: 155,
    villaQuadrofamiliare: 156,
    villaPlurifamiliare: 157,
    villaStorica: 159,
    porzioneDiVilla: 160,
    garagePostoAuto: 162,
    postoBarca: 163,
    postoAuto: 164,
    postoMoto: 165,
    postoCamperRoulotte: 166,
    residentialAutoSilos: 167,
    residentialGarage: 168,
    terratettoPlurifamiliare: 170,
    terratettoUnifamiliare: 171,
    terratetto: 172,
    cantina: 177,
    soffitta: 178,
    residentialPertinenze: 179,
    localeCommerciale: 184,
    residentialAltro: 222,
}

export const SQM_ROOMS_CHECK_EXCLUDED_TYPOLOGY_LIST: number [] = [
    TYPOLOGY.box,
    TYPOLOGY.garagePostoAuto,
    TYPOLOGY.postoBarca,
    TYPOLOGY.postoAuto,
    TYPOLOGY.postoMoto,
    TYPOLOGY.postoCamperRoulotte,
    TYPOLOGY.residentialAutoSilos,
    TYPOLOGY.residentialGarage,
    TYPOLOGY.cantina,
    TYPOLOGY.soffitta,
    TYPOLOGY.residentialPertinenze,
    TYPOLOGY.rustico,
    TYPOLOGY.cascina,
    TYPOLOGY.casaColonica,
    TYPOLOGY.trullo,
    TYPOLOGY.casale,
    TYPOLOGY.nuraghe,
    TYPOLOGY.dammuso,
    TYPOLOGY.maso,
    TYPOLOGY.baglio,
    TYPOLOGY.rifugio,
    TYPOLOGY.sasso,
    TYPOLOGY.masseria,
    TYPOLOGY.abitazioneTipica,
    TYPOLOGY.albergo,
    TYPOLOGY.aziendaAgricola

]

export const NO_SALE_PRICE_PER_SQM_TYPOLOGIES: number[] = [
    TYPOLOGY.villa,
    TYPOLOGY.villaSchiera,
    TYPOLOGY.villaBifamiliare,
    TYPOLOGY.villaTrifamiliare,
    TYPOLOGY.villaQuadrofamiliare,
    TYPOLOGY.villaPlurifamiliare,
    TYPOLOGY.villaStorica,
    TYPOLOGY.casaIndipendente,
    TYPOLOGY.rustico,
    TYPOLOGY.palazzo,
    TYPOLOGY.cascina,
    TYPOLOGY.casale,
    TYPOLOGY.albergo,
    TYPOLOGY.porzioneDiCasa,
    TYPOLOGY.terratetto,
    TYPOLOGY.terratettoPlurifamiliare,
    TYPOLOGY.terratettoUnifamiliare,
    TYPOLOGY.casaColonica,
    TYPOLOGY.postoAuto,
    TYPOLOGY.baita,
    TYPOLOGY.cameraStanza,
    TYPOLOGY.stanzaInVilla,
    TYPOLOGY.stanzaInAppartamento,
    TYPOLOGY.stanzaInCasaIndipendetente,
    TYPOLOGY.trullo,
    TYPOLOGY.aziendaAgricola,
    TYPOLOGY.palazzoStorico,
    TYPOLOGY.castello,
    TYPOLOGY.garage,
    TYPOLOGY.dimoraStorica,
    TYPOLOGY.masseria,
    TYPOLOGY.cantina,
    TYPOLOGY.baglio,
    TYPOLOGY.residentialAltro,
    TYPOLOGY.sasso,
    TYPOLOGY.postoBarca,
    TYPOLOGY.soffitta,
    TYPOLOGY.abitazioneTipica,
    TYPOLOGY.postoMoto,
    TYPOLOGY.dammuso,
    TYPOLOGY.porzioneDiVilla,
    TYPOLOGY.pertinenze,
    TYPOLOGY.immobileStorico,
    TYPOLOGY.torre
];
export const AGENCY_PREMIUM = 2;
export const REAL_ESTATE_TYPOLOGY_VILLA_ID = 2;
export const REAL_ESTATE_TYPOLOGY_VILLA_A_SCHIERA_ID = 3;
export const REAL_ESTATE_TYPOLOGY_CASA_INDIPENDENTE_ID = 5;
export const PRO_PREFIX = "/pro";
export const MIN_COMPARABLES_PER_OFFER_DATA = 3;

// REGEX
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/mi;
export const EMAIL_REGEX_INTO_TEXT = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
export const COMPETITOR_REGEX = /(https:\/\/)?(www.)?(immobiliare|idealista).it([/a-zA-Z0-9-]+)/mi;
// eslint-disable-next-line max-len
export const WEBSITE_REGEX = /^(https?:\/\/)?(([0-9a-z_!~*'().&=+$%-]+:)?[0-9a-z_!~*'().&=+$%-]+@)?(([0-9]{1,3}\.){3}[0-9]{1,3}|([0-9a-z_!~*'()-]+\.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\.[a-z]{2,6})(:[0-9]{1,4})?((\/?)|(\/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+\/?)$/mi;
export const LINKEDIN_REGEX = /^(http(s?):\/\/)(www\.)?linkedin\.com\/?.*/mi;
export const FACEBOOK_REGEX = /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/mi;
export const INSTAGRAM_REGEX = /^(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/mi;
export const PHONE_REGEX = /([0-1]?[1-9][/.][0-1][0-9][/.]\d{2,4})|(\+?[0-9])([/\\ .-]*[0-9]){8,}/g;
export const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}/g;
export const EXCLUDED_REGEX = ["S ID: ", "RIF: R", "RIF. "];

export const MAP_TILER_STYLE = "43db46b1-02d3-4fe1-ae7b-cd02b1aa87e0";

//BREAKPOINT
export enum BREAKPOINT {
    SMALL = 576,
    MEDIUM = 768,
    LARGE = 992,
    EXTRA_LARGE = 1200,
}

export enum SHARE_OUT {
    EMAIL = 1,
    FACEBOOK,
    WHATSAPP,
    TELEGRAM,
    MESSANGER,
    LINKEDIN,
    INSTAGRAM,
}

export enum ENERGY_CLASS {
    HIGH = 1,
    MEDIUM,
    LOW
}

export enum SERVICE {
    ADSL = 0,
    GAS,
    LIGHT,
    LIGHT_GAS,
    MORTGAGE
}

export enum TOP_CITY_IDS {
    ROMA = 58091,
    MILANO = 15146,
    PALERMO = 82053,
    TORINO = 1272,
    GENOVA = 10025,
    NAPOLI = 63049,
    BOLOGNA = 37006,
    FIRENZE = 48017,
    CATANIA = 87015,
    BARI = 72006,
    MESSINA = 83048,
    CAGLIARI = 92009
}

declare global {
    interface Window {
        touch: boolean;
        CustomEvent: any;
        GAPI: boolean;
        _macroTypology1Label: string;
        _macroTypology2Label: string;
        _macroTypology3Label: string;
        _macroTypology4Label: string;
        _macroTypology5Label: string;
        _macroTypology6Label: string;
        _rentLabel: string;
        _saleLabel: string;
        _auctionLabel: string;
        _chooseOptionLabel: string;
        _primaryPrefix: string;

        [key: string]: any;
    }
}


export function validateField(field: JQuery | undefined): boolean {

    if (!field)
        return false;

    if (!field.val()) {
        field.addClass("has-error");
        setTimeout(() => {
            if (field.hasClass("has-error"))
                field.removeClass("has-error");
        }, 5000);
        return false;
    }
    return true;
}

export function formatCurrency(n: number | undefined): string {
    if (n==undefined)
        return "";

    return n.toFixed(0).replace(/./g, (c: string, i: number, a: string) =>
        i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c);
}

//Andrea A: funzione per l'esecuzione delle RegeX
export function replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, "g"), replace);
}

export function buildURL(cityURL: string, listingTypology: string, contractType: number | undefined | null, zone?: string | null, seoFilter?: string | null): string {

    let URL = "/";

    if (contractType) {
        URL += (contractType === TYPE_AUCTION ?
            window["_auctionLabel"] : contractType === TYPE_RENT ? window["_rentLabel"] : window["_saleLabel"]) + "-";
    }

    URL += `${listingTypology}/`;

    if (cityURL)
        URL += `${cityURL.replace(/^-+|-+$/g, "")}/`;

    if (zone) {
        URL += `${zone.replace(/^_+|_+$/g, "")}/`;
    }

    if (seoFilter) {
        URL += `${seoFilter.replace(/^_+|_+$/g, "")}/`;
    }
    return URL;
}

export function buildParams(searchBean: RealEstateSearchBean): string {
    const params = Object.keys(searchBeanMap).map(key => {
        if (searchBean[key]) {
            return encodeURIComponent(searchBeanMap[key]) + "=" +
                encodeURIComponent(searchBean[key]);
        } else return null
    }).filter(param => param !== null);

    return params.length ? `?${params.join("&")}` : "";
}

export function buildParamsForAgency(searchBean: RealEstateSearchBean): string {
    const params = Object.keys(searchBeanMapAgencyFilter).map(key => {
        if (searchBean[key]) {
            if (Array.isArray(searchBean[key])) {
                const arr = searchBean[key];
                const nameField = searchBeanMapAgencyFilter[key];
                let strToReturn = "";
                for (let i = 0; i < arr.length; i++) {
                    strToReturn += encodeURIComponent(nameField) + "=" +
                        encodeURIComponent(arr[i]);
                    strToReturn += (i < arr.length - 1) ? "&" : "";
                }
                return strToReturn;
            } else {
                return encodeURIComponent(searchBeanMapAgencyFilter[key]) + "=" +
                    encodeURIComponent(searchBean[key]);
            }

        } else return null
    }).filter(param => param !== null);

    return params.length ? `?${params.join("&")}` : "";
}

// eslint-disable-next-line max-len
export function buildCasaclickURL(provinceURL: string, cityURL: string, listingTypology: string, contractType: number | undefined | null, zone?: string | null, seoFilter?: string | null): string {

    let URL = "/";

    if (provinceURL)
        URL += `${provinceURL.toLowerCase().replace(/^-+|-+$/g, "")}/`;

    if (cityURL && !cityURL.startsWith("provincia"))
        URL += `${cityURL.replace(/^-+|-+$/g, "")}/`;

    if (zone)
        URL += `${zone.replace(/^_+|_+$/g, "")}/`;

    if (contractType) {
        URL += (contractType === TYPE_AUCTION ?
            window["_auctionLabel"] : contractType === TYPE_RENT ? window["_rentLabel"] : window["_saleLabel"]) + "-";
    }

    URL += `${listingTypology}/`;

    if (seoFilter) {
        URL += `${seoFilter.replace(/^_+|_+$/g, "")}/`;
    }
    return URL;
}

export function buildBlinksURL(cityURL: string, listingTypology: string, contractType: number | undefined | null, zone?: string | null, seoFilter?: string | null): string {

    let URL = "/re/";

    if (listingTypology) URL += `tipologia-${listingTypology}/`;

    if (cityURL) URL += `luogo-${cityURL}/`;

    if (zone)
        URL += `${zone.replace(/^_+|_+$/g, "")}/`;

    if (contractType) {
        URL += "contratto-" + (contractType === TYPE_AUCTION ?
            window["_auctionLabel"] : contractType === TYPE_RENT ? window["_rentLabel"] : window["_saleLabel"]);
    }

    if (seoFilter) {
        URL += `${seoFilter.replace(/^_+|_+$/g, "")}`;
    }
    return URL;
}

export function getZones(cityURL: string): JQueryPromise<unknown> {
    return $.ajax({
        dataType: "json",
        contentType: "application/json",
        type: "GET",
        url: "/ws/zones?urlPartName=" + cityURL
    });
}

//Andrea A: funzione per formattare i numeri, con digits specifichiamo il separatore decimale
export function formatNumber(value: number | undefined, digits?: number): string {

    if (!value && value !== 0)
        return "";

    return value.toLocaleString(
        "it-IT", // usare una stringa tipo 'en-US' per sovrascrivere la lingua del browser
        {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        }
    );
}

export function formatInteger(value: number | undefined, round: boolean = false): string {
    if (value == null)
        return "";

    return formatNumber(round ? Math.round(value) : Math.floor(value), 0);
}

export function formatFloat(value: number | undefined, digits: number = 2): string {
    return formatNumber(value, digits);
}

export function formatDate(date: Date, display: boolean = false, separateWithSlash: boolean = false, hours: boolean = false, minutes: boolean = false): string {
    let formatString = "YYYY-MM-DD";

    if (display) {
        formatString = separateWithSlash ? "DD/MM/YYYY" : "DD-MM-YYYY";
    }

    if (hours && minutes) {
        formatString += " - HH:mm";
    }

    return moment(date).format(formatString);
}


export function loadGAPI(libraries: Array<string>): void {

    if (window["GAPI"])
        return;

    const key = window["_GMapsApiKey"] || "AIzaSyAwPvFZZcRgprmXrzEqBdtDph_UpntvlEQ";
    const librariesText = libraries.join();

    inject(`https://maps.googleapis.com/maps/api/js?v=quarterly&key=${key}&libraries=${librariesText}&language=it`, true, () => {
        const event = createCustomEvent("gapi-ready", {
            detail: {
                description: "Google Maps API are successfully loaded",
                key: key,
                libraries: libraries
            }
        });
        document.dispatchEvent(event);
    });

    window["GAPI"] = true;
}

export function inject(src: string, async: boolean, callback?: (() => void) | null, id?: string): void {
    const script = document.createElement("script");
    script.type = "text/javascript";

    if (async) {
        script.setAttribute("async", "");
    }

    script.src = src;

    if (id)
        script.id = id;

    if (callback)
        script.onload = (): void => {
            callback();
        };

    document.head.appendChild(script);
}

export function createCustomEvent(event: string, params?: CustomEventInit): Event {

    if (typeof window.CustomEvent === "function") {
        return new CustomEvent(event, params);
    }

    params = params || {bubbles: false, cancelable: false, detail: undefined};
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(event, !!params.bubbles, !!params.cancelable, params.detail);
    return evt;
}

export interface IExtendJQuery extends JQuery {
    once(events: string, fn: (e: Event, stop: boolean) => unknown): () => void;

    serializeJson(): any;
}

$.fn.extend({
    once: function (events: string, fn: () => void): void {

        let timeout: NodeJS.Timeout;

        $(this).on(events, (e: JQuery.Event, args: never) => {

            if (timeout)
                clearTimeout(timeout);

            timeout = setTimeout(() => fn.call(this, e, args), 100);
        });

    },
    serializeJson: function (this: JQuery<Element>, options?: Record<string, any>) {

        const settings = $.extend({
            stringify: false
        }, options);

        const json: Record<string, any> = {};
        const array = this.serializeArray();

        for (let i = 0, len = array.length; i < len; i++) {
            const param = array[i];

            if (json[param.name]) {
                if (!json[param.name].push)
                    json[param.name] = [json[param.name]];
                json[param.name].push(param.value || "");
            } else
                json[param.name] = param.value || "";
        }

        if (settings.stringify)
            return JSON.stringify(json);
        else
            return json;
    },

    visible: function () {
        return isVisible($(this)[0] as HTMLElement);
    }
});

export function getQueryStringParameter(key: string, url: string = window.location.href): string | null {
    key = key.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

$(() => {

    const $inputs: JQuery = $("input.form-control, textarea.form-control");
    const $selects: JQuery = $("select.form-control");
    const $closeButton: JQuery = $(".forced-close")

    $closeButton.on("click", () => {
        $(".modal").modal("hide");
    });

    $inputs.add($selects).on("change input", function (this: HTMLFormElement) {
        if (this.value || ($(this).attr("placeholder") && $(this).attr("placeholder") !== "")) {
            $(this).addClass("has-value");
            $(this).removeClass("has-error");
            $(this).closest(".form-error").removeClass("form-error");
        } else
            $(this).removeClass("has-value");
    });

    $inputs.on("focus", function () {
        $(this).closest(".form-group").addClass("form-focus");
    });

    $inputs.on("blur", function () {
        $(this).closest(".form-group").removeClass("form-focus");
    });

    $inputs.trigger("change");

    $("#logoutBtn").click(() => {

        $.ajax({
            type: "GET",
            url: "/rest/doLogout",
            success: () => {
                location.href = "/";
            },
            error: () => {
                console.error("Si è verificato un problema con il logout");
            }
        });
    });
});


export function isVisible(node: HTMLElement, full: boolean = false): boolean {
    const rect = node.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        (full ? rect.bottom : rect.top) <= (window.innerHeight || document.documentElement.clientHeight) &&
        (full ? rect.right : rect.left) <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

interface IOffset {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export function offset(node: Element, parent: Element | Node): IOffset {

    const nodeRect = node.getBoundingClientRect();

    if (!parent)
        return nodeRect;

    const parentRect = (parent as Element).getBoundingClientRect();

    return {
        top: nodeRect.top - parentRect.top,
        left: nodeRect.left - parentRect.left,
        right: nodeRect.right - parentRect.right,
        bottom: nodeRect.bottom - parentRect.bottom
    }
}

export function updateQueryStringParameter(uri: string, key: string, value: string): string {
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    if (uri.match(re)) {
        return value ? uri.replace(re, "$1" + key + "=" + value + "$2") : uri.replace(re, (a: string, b: string) => {
            const multiple = uri.indexOf("&") !== -1;
            return multiple ? b : "";
        });
    } else {
        const separator = uri.indexOf("?") !== -1 ? "&" : "?";
        return value ? uri + separator + key + "=" + value : uri;
    }
}

export function detectTouch(): void {
    window.addEventListener("touchstart", function onFirstTouch() {
        window["touch"] = true;
        window.removeEventListener("touchstart", onFirstTouch, false);
    }, false);
}

$(document).ready(() => {

    detectTouch();

    const $elems = $("input, select");

    $elems.on("change input", function () {

        const self = this as HTMLInputElement;

        let $selectors = $(this);

        if (self.type === "radio") {
            $selectors = $elems.filter(`[name="${self.name}"]`);
        }

        if (self.value || self.checked) {
            $selectors.addClass("has-value");
            $selectors.removeClass("has-error");
            $selectors.closest(".has-error").removeClass("has-error");
        } else
            $selectors.removeClass("has-value");

    });

    $elems.on("focus", function () {
        $(this).closest(".form-group").addClass("form-focus");
    });

    $elems.on("blur", function () {
        $(this).closest(".form-group").removeClass("form-focus");
    });

    const $suggestionModal = $("#suggestion-modal");
    const $suggestionTextArea = $("#suggestion-message")
    const $formSuggestion = $suggestionModal.find("form");
    const MAX_LENGTH_EMAIL_ARGUMENT = 100;
    const MAX_LENGTH_MESSAGE = 400;
    const $counter = $("#charNum");



    $suggestionTextArea.on("input", () => {
        // @ts-ignore
        const len = $suggestionTextArea.val().length
        $counter.text(MAX_LENGTH_MESSAGE - len + " / 400");

        if (len === MAX_LENGTH_MESSAGE)
            $counter.addClass("has-error")
        else
            $counter.removeClass("has-error")
    })

    if ($formSuggestion.length) {
        const formSuggestion = new Form($formSuggestion, {discardEmptyValues: true});
        const $suggestButton = $formSuggestion.find("button");


        formSuggestion.onSubmit((e: JQuery.Event) => {
            e.preventDefault();

            if (!formSuggestion.validate(true)) {
                formSuggestion.clearSubmit();
                return;
            }

            if (formSuggestion.getField("email")?.getValue().email.length > MAX_LENGTH_EMAIL_ARGUMENT || formSuggestion.getField("argument")?.getValue().argument.length > MAX_LENGTH_EMAIL_ARGUMENT || formSuggestion.getField("message")?.getValue().message.length > MAX_LENGTH_MESSAGE) {
                formSuggestion.clearSubmit()
                $suggestButton.html(`${window["_error"]}`).attr("disabled", "disabled");
                return;
            }

            const addProPrefix = !!$suggestionModal.data("proPrefix")

            const suggestion = formSuggestion.getValues();

            sendSuggestion(suggestion, addProPrefix).done(() => {
                $suggestButton.html(`${window["_suggestionSendOkLabel"]}!`).attr("disabled", "disabled");
                setTimeout(() => $suggestionModal.modal("hide"), 3000);
            }).fail((error: any) => {
                if ((error.status === 400) || (error.status === 401)) {
                    formSuggestion.getSelector().find(".form-error").show();

                } else
                    console.error(error);
                Icons.removeSpinner($suggestButton);
            });
        });
    }
});

export function shuffle<T>(array: Array<T>): Array<T> {

    const _arr = array.slice();
    let currentIndex = _arr.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = _arr[currentIndex];
        _arr[currentIndex] = _arr[randomIndex];
        _arr[randomIndex] = temporaryValue;
    }

    return _arr;
}

/**
 * @return {string}
 */
export function rgba(hex: string, opacity: number): string {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getParam(name: string, url?: string | undefined): string | null {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function setParam(key: string, value: string, url?: string | undefined): string {
    if (!url) url = window.location.href.replace(window.location.hash, "");
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = url.indexOf("?") !== -1 ? "&" : "?";
    if (url.match(re)) {
        return url.replace(re, "$1" + key + "=" + value + "$2");
    } else {
        return url + separator + key + "=" + value;
    }
}

export function removeParam(key: string, url?: string | undefined): string {
    if (!url) url = window.location.href.replace(window.location.hash, "");
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    params.delete(key);
    urlObj.search = params.toString();
    return urlObj.toString();
}

export function parsePoints(polygonFromMap: string): Array<LatLng> | undefined {

    if (!polygonFromMap)
        return;

    const numbers: RegExpMatchArray | null = polygonFromMap.match(/[-]?[\d]*[.]?[\d]+/g);

    if (!numbers)
        return;

    const latLngs: Array<LatLng> = [];

    for (let i = 0; i < numbers.length; i++) {
        latLngs.push({lat: parseFloat(numbers[i + 1]), lng: parseFloat(numbers[i])} as LatLng);
        i++;
    }

    return latLngs;
}

export function removeDuplicatePoints(latLngs: Array<LatLng>): Array<LatLng> {
    if (latLngs.length <= 0) return [];

    const uniqueCoordinates = new Set();
    const unique = latLngs.filter(x => {
        const isDuplicate = uniqueCoordinates.has(x.lat + x.lng);
        const coordinate = x.lat + x.lng;
        uniqueCoordinates.add(coordinate);
        return !isDuplicate;

    });

    return unique;
}


export function getPolygonString(shapePoints: Array<LatLng>): string {
    let polygonString = "POLYGON((";
    shapePoints.forEach((point: LatLng) => {
        polygonString += `${point.lng} ${point.lat}, `;
    });
    polygonString += `${shapePoints[0].lng} ${shapePoints[0].lat}))`;
    return polygonString;
}

export function decodePoints(polygon: string): Array<LatLng> {
    const points = JSON.parse(decodeURIComponent(polygon))
    return points.map((point: any) => {
        return {lat: point.LAT, lng: point.LNG}
    })
}

export function calcPolygonCenter(points: Array<LatLng>): LatLng {

    let lat = 0;
    let lng = 0;

    for (let i = 0; i < points.length; i++) {

        const point = points[i];

        lat += point.lat;
        lng += point.lng;
    }

    return {lat: lat / points.length, lng: lng / points.length} as LatLng;
}

export function encodePoints(latLngs: Array<LatLng>): string {

    let str = "";

    for (let i = 0; i < latLngs.length; i++) {
        str += `${latLngs[i].lat.toFixed(6)},${latLngs[i].lng.toFixed(6)};`;
    }

    return str;
}

export function validateMap(map: Leaflet_map): boolean {

    const mapRange = window._mapRange || 10000;

    if (map) {
        const center = map.getShape()?.getCenter();
        const points = map.getShapePoints();
        if (points) {
            for (let i = 0; i < points.length; i++) {
                if (map.getDistance(points[i], center) > mapRange)
                    return false;
            }
        }
        return true;
    }
    return false;
}

export function observe(selector: string, fn: (Element: Node, IntersectionObserver: IntersectionObserver) => void, options?: IntersectionObserverInit): void {
    const elems = [].slice.call(document.querySelectorAll(selector));

    const observer = new IntersectionObserver((entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) => {

        for (let i = 0; i < entries.length; i++) {

            const entry = entries[i];

            if (entry.isIntersecting) {
                const elem = entry.target;
                fn(elem, observer);
                observer.unobserve(elem);
            }
        }
    }, options);

    elems.forEach((elem: HTMLElement) => {
        observer.observe(elem);
    });
}

let searchBean: Record<string, any> | null;

export function getSearch(): Record<string, any> | undefined | null {

    if (searchBean)
        return searchBean;

    if (!(window["_serializedSearchBean"] || window["_serializedAgencySearchBean"]))
        return;

    try {
        searchBean = JSON.parse(window._isAgencyCategory ?
            window["_serializedAgencySearchBean"] :
            window["_serializedSearchBean"]);
        //searchBean = JSON.parse(window["_serializedSearchBean"] ? window["_serializedSearchBean"] : window["_serializedAgencySearchBean"]);
    } catch {
        searchBean = null;
    }

    return searchBean;
}

let realEstateBean: Record<string, any> | null;

export function getRealEstateBean(): Record<string, any> | null {

    if (realEstateBean)
        return realEstateBean;

    if (!window["_serializedRealEstateBean"])
        return null;

    try {
        realEstateBean = JSON.parse(window["_serializedRealEstateBean"]);
    } catch {
        realEstateBean = null;
    }

    return realEstateBean;
}

export function isBot(useragent: string): boolean {
    const botregex = /bot|crawler|spider|crawling/;
    return botregex.test(useragent);
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatContractType(sale: boolean, rent: boolean, auction: boolean, bareOwnership: boolean): string {

    const components: Array<string> = [];

    if (auction)
        components.push("Asta");

    if (bareOwnership)
        components.push("Nuda proprietà");

    if (sale && !auction && !bareOwnership)
        components.push("Vendita");

    if (rent)
        components.push("Affitto");

    return components.join(" / ");
}

export function formatPrice(sale: boolean, priceSale: number, rent: boolean, priceRent: number): string {

    const components: Array<string> = [];

    if (sale)
        components.push(`€ ${formatCurrency(priceSale)}`);

    if (rent && components.length)
        components.push(`(${formatCurrency(priceRent)}/mese)`);
    else if (rent)
        components.push(`€ ${formatCurrency(priceRent)}/mese`);

    return components.join(" ");
}

export function formatAddress(streetName: string, streetNumber: string, cityName: string, provinceAcronym?: string): string {

    const components: Array<string> = [];

    if (streetName && streetName !== "ND") {
        if (streetNumber && streetNumber !== "ND" && streetNumber !== "0")
            components.push(`${streetName} ${streetNumber}`);
        else
            components.push(streetName);
    }

    if (cityName && provinceAcronym) {
        components.push(`${cityName} (${provinceAcronym})`);
    } else {
        components.push(cityName);
    }

    const address = components.filter((component: string) => !!component).join(", ");

    return capitalizeFirstLetter(address);
}

export function decodeXml(string: string): string {
    return string.replace(/&apos;/g, "'")
        .replace(/&quot;/g, "\"")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&amp;/g, "&");
}

export function cleanASCII(str: string): string {
    return str.replace(/[\uA78C\uA78B]/g, "'").replace(/[^\x00-\x7F]/g, "");
}

export function encodeCookie(name: string, obj: unknown, days: number = 30): void {
    const d = new Date;
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + btoa(encodeURIComponent(JSON.stringify(obj))) + ";path=/;expires=" + d.toUTCString();
}

export function getCookie(name: string): string | null {
    try {
        const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return v ? v[2] : null;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export function decodeCookie(name: string): any {
    try {
        const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return v ? JSON.parse(decodeURIComponent(atob(v[2]))) : null;
    } catch (e) {
        console.error(e);
        removeCookie(name);
        return null;
    }
}

export function removeCookie(name: string): void {
    Cookies.remove(name);
}

export function groupBy(xs: any, f: (arg?: any) => void): Record<any, any> {
    return xs.reduce((r: any, v: any, i: any, a: any, k: any = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function renderModal(id: string, title: string, classes: string, body?: JQuery, footer?: JQuery): JQuery {

    // eslint-disable-next-line max-len
    const $modalWrapper = $(`<div id="${id}" class="modal modal-panel ${classes}" tabindex="-1" role="dialog" aria-modal="true"><div class="modal-dialog"><div class="modal-content"></div></div></div>`);
    // eslint-disable-next-line max-len
    const $modalHeader = $(`<div class="modal-header"><span class="modal-title d-sm-none w-100">${title}</span><button type="button" class="close d-sm-none mr-0" data-dismiss="modal" aria-label="Close"><span class="d-none d-sm-inline" aria-hidden="true">×</span><img height="32px" width="32px" src="${window._cdn}/_ui/img/Icons/Close-gs.svg" class="icon icon-lg" alt="Icona indietro"></button></div>`);
    const $modalBody = $(`<div class="modal-body"></div>`);
    const $modalFooter = $(`<div class="modal-footer"></div>`);

    $modalWrapper.find(".modal-content").append($modalHeader);

    if (body) {
        $modalBody.append(body ?? "");
        $modalWrapper.find(".modal-content").append($modalBody);
    }

    if (footer) {
        $modalFooter.append(footer ?? "");
        $modalWrapper.find(".modal-content").append($modalFooter);
    }

    return $modalWrapper;
}

export function getContractId(contract: { bareOwnership: number, auction: boolean, sale: boolean, rent: boolean }): number {
    return contract.bareOwnership ? 5 : contract.auction ? 4 : contract.sale && contract.rent ? 3 : contract.rent ? 2 : 1
}

export function sendSuggestion(suggestion: any, proPrefix: boolean = false): JQueryPromise<unknown> {
    suggestion["url"] = window.location.href;
    return $.ajax({
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(suggestion),
        url: `${proPrefix ? "/pro" : ""}/rest/request/sendSuggestion`
    });
}

$("body").on("click", "[data-href]", function () {
    window.location.href = $(this).data("href");
});

export const decodeEntity = (inputStr: string): string => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = inputStr;
    return textarea.value;
}
