import * as Common from "../common";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default abstract class Field {
    private readonly _selector: JQuery;
    private readonly _name: string;
    private _required: boolean;
    private readonly _type: string;
    private readonly _form: Form;
    private readonly _squeeze: boolean;

    protected constructor($selector: JQuery, form: Form, type: string = "string") {
        this._selector = $selector;
        this._name = $selector.prop("name");
        this._required = !!$selector.prop("required");
        this._type = type;
        this._form = form;
        this._squeeze = !!$selector.data("squeeze");
    }

    get name(): string {
        return this._name;
    }

    get type(): string {
        return this._type;
    }

    get required(): boolean {
        return this._required;
    }

    set required(required: boolean) {
        this._selector.siblings("label").toggleClass("label-required", required);
        this._selector.prop("required", required);
        if (!required)
            this._selector.removeClass("has-error");
        this._required = required
    }

    get selector(): JQuery {
        return this._selector;
    }

    get form(): Form {
        return this._form;
    }

    get squeeze(): boolean {
        return this._squeeze;
    }

    abstract getValue(): any;

    abstract setValue(value: any): void;

    abstract isValid(): boolean;

    abstract clear(): void;

    protected processValue(value: any): any {

        const reducer = (acc: any, curr: any): any => ({[curr]: acc});

        const arr = this.name.split(".").reverse();
        const prop = String(arr.shift());

        const entry: Record<string, unknown> = {};

        // If value is an object with valid json property directly return
        if (value === Object(value) && !(value instanceof Date)) {
            return value;
        } else if (value !== null && value !== undefined) switch (this.type) {
            case "string" :
            case "email" :
                const str = String(value).replace(/^-+|-+$/g, "");
                if (str.length) entry[prop] = str;
                break;
            case "number" :
                const val = parseInt(value);
                if (val > 0) {
                    entry[prop] = val;
                    break;
                }
                if (this.selector.data("allowZero")) {
                    entry[prop] = parseInt(String(this.selector.val())) === 0 ? 0 : null;
                }
                break;
            case "boolean" :
                if (String(value) === "false" || String(value) === "true")
                    entry[prop] = String(value) === "true";
                break;
            case "date":
                if (value instanceof Date) entry[prop] = Common.formatDate(value);
                break;
        }
        // if not nested property return object with null property value
        // eslint-disable-next-line no-prototype-builtins
        if (!this.form.getOptions().discardEmptyValues && !entry.hasOwnProperty(prop))
            entry[prop] = null;

        if (arr.length > 0) {

            if (this.squeeze && entry[prop] === null)
                return arr.reduce(reducer, null);

            return arr.reduce(reducer, entry);
        }
        return entry;
    }

    protected isRequired(): boolean {
        return this.required;
    }

    protected processValid(value: any): boolean {
        if (this.selector.data("regex") && (this.isRequired() || !$.isEmptyObject(value))) {
            const nestedValue = this.name.split(".").reduce((obj: Record<string, unknown>, i: string) => obj[i], value);

            const regExp = new RegExp(this.selector.data("regex"));

            if (!regExp.test(nestedValue)) {
                return false;
            }
        }

        return true;
    }

    hasValue(): boolean {
        const value = this.getValue();

        if (value[this.name] == null)
            return false;

        const nestedValue = this.name.split(".").reduce((obj: Record<string, unknown>, i: string) => obj[i], value);
        return nestedValue != null;
    }
}