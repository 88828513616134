import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min";

export function init($selector: JQuery, opts?: any): void {

    const defOpts = {
        autoclose: true,
        language: "it",
        startDate: $selector.data("start-date") || new Date(0),
        endDate: $selector.data("end-date") || "today",
        pickTime: false,
        container: $selector.parent()
    };

    const params = $.extend({}, defOpts, opts);

    $selector.datepicker(params);

    $selector.datepicker().on("show.bs.modal hide.bs.modal", (event: JQuery.Event) => {
        // prevent datepicker from firing bootstrap modal "show.bs.modal"
        event.stopPropagation();
    });

    if (params.default)
        $selector.datepicker("setDate", params.default);

}

export function link(selector1: JQuery, selector2: JQuery): void {

    const $selector1 = $(selector1);
    const $selector2 = $(selector2);

    $selector1.on("changeDate", (datepicker: DatepickerEventObject) => {

        const date = new Date(datepicker.date.valueOf());
        const endDate = $selector2.datepicker("getDate");

        if (endDate && endDate < date)
            $selector2.datepicker("setDate", date);
    });

    $selector2.on("changeDate", (datepicker: DatepickerEventObject) => {

        const date = new Date(datepicker.date.valueOf());
        const startDate = $selector1.datepicker("getDate");

        if (startDate && startDate > date)
            $selector1.datepicker("setDate", date);
    });
}