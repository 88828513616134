import Field from "./Field";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default class CheckboxField extends Field {

    constructor($input: JQuery, form: Form, type: string = "boolean") {
        super($input, form, type);

        if ($input.data("show")) {

            $input.change(() => {
                const $target = this.form.getSelector().find($input.data("show"));

                if ($input.is(":checked"))
                    $target.attr("data-hidden", "false").show();
                else
                    $target.attr("data-hidden", "true").hide();
            });
        }

        if ($input.data("hide")) {

            $input.change(() => {
                const $target = this.form.getSelector().find($input.data("hide"));
                if (!$input.is(":checked"))
                    $target.attr("data-hidden", "false").show();
                else
                    $target.attr("data-hidden", "true").hide();
            });
        }
    }

    getValue(): any {
        const value = this.form.getOptions().discardEmptyCheckbox && !this.selector.is(":checked") ? undefined :
            this.selector.data("invert") ? !this.selector.is(":checked") : this.selector.is(":checked");

        return super.processValue(value);
    }

    setValue(value: boolean): void {
        this.selector.data("invert") ?
            this.selector.prop("checked", !value).trigger("change") :
            this.selector.prop("checked", value).trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this.selector.is(":checked") ? false : super.processValid(this.getValue());
    }
    clear(): void {
        this.setValue(false);
    }
}