/**
 * @author Andrea Moraglia
 */

export default interface IRealEstateSearchBean {
    Id?: number;
    agencyId?: number;
    agentId?: number;
    airConditioning?: boolean;
    areaId? :number;
    areaOfInterestId?: number;
    auctionType?: number;
    balconyFrom?: number;
    balconyTerrace?: boolean;
    balconyTo?: number;
    balcony?: boolean;
    bank?: boolean;
    bareOwnershipType?: number;
    bathroomsFrom?: number;
    bathroomsTo?: number;
    bothReference?: string;
    reference?: string;
    boxCarSpot?: boolean;
    box?: boolean;
    carSpot?: boolean;
    cellar?: boolean;
    charging?: boolean;
    cityDisplayName?: string;
    cityId?: number;
    cityURL?: string;
    conditionType?: number;
    contractType?: number;
    energyClassFilter?: number | string | null;
    energyClassType?: number;
    energyClass?: number;
    excludeAuctions?: boolean;
    excludeBareOwnership?: boolean;
    onlyBareOwnership?: boolean;
    excludedAgentId?: number;
    excludedIdList?: Array<number>;
    expired?: boolean;
    filterOrder?: number;
    filtersCount?: number;
    floorId?: number
    floor?: number | null;
    furnished?: boolean;
    heatingTypeCentralized?: boolean;
    heatingTypeIndipendent?: boolean;
    hospital?: boolean;
    includePublicUser?: boolean;
    inReview?: boolean;
    insertDate?: Date;
    isAllRealEstate?: boolean;
    latitude?: number;
    lift?: boolean;
    listingTypologyURL?: string;
    localityIdList?: Array<number>
    longitude?: number;
    macroTypologyId?: number;
    macroTypologyIdList?: Array<number> | null;
    market?: boolean;
    onlyPublicUser?: boolean;
    onlyVisible?: boolean;
    pharmacy?: boolean;
    placeId?: number;
    placeType?: string;
    polygonForDB?: string;
    polygonFromMap: string;
    portal?: PORTAL
    post?: boolean;
    priceFrom?: number;
    priceTo?: number;
    privateGarden?: boolean;
    provinceId?: number;
    provinceURL?: string;
    publicGarden?: boolean;
    publicTransport?: boolean;
    recent?: boolean
    reception?: boolean;
    regionId?: number;
    rent?: boolean;
    roomsFrom?: number;
    roomsTo?: number;
    sale?: boolean;
    school?: boolean;
    shortRentOnly?: boolean;
    shortRentType?: number;
    sqMfrom?: number;
    sqMto?: number;
    streetURL?: string;
    subscriptionTypes?: Array<number>;
    swimmingPoolPrivate?: boolean;
    swimmingPoolPublic?: boolean;
    terrace?: boolean;
    typologyIdList?: Array<number>;
    listingTypologyId: number | null;
    listingTypologyIdComparables: number | null;
    listingTypologyIdList?: Array<number>;
    typologyId?: number;
    zoneIdList?: Array<number>;
    cityIdList?: Array<number>;
    zoneId?: number | null;
    zoneURL?: string | null;
    districtURL?: string | null;
    districtId?: number | null;
    districtIdList?: Array<number>;
    luxury?: boolean;
    discounted?: boolean;
    auctionBasePrice?: number | null,
    auctionMinimumOffer?: number | null,
    auctionMinimumOfferFrom?:number;
    auctionMinimumOfferTo?:number;
    auctionDateFrom?: string,
    auctionDateTo?: string
    auctionPvpPublicationDateTo?: string,
    auctionPvpPublicationDateFrom?: string,
    auctionMinimumBid?:number;
    auctionCourtTypeID?:number;
    auctionSaleModeID?:number;
    auctionProcedureNumber?:number;
    auctionReferenceYear?: number;
    auctionPublicationDateFrom?:string;
    auctionPublicationDateTo?:string;
    auctionProcedureTypologyID?: number;
    avmEvaluationFrom?: number;
    avmEvaluationTo?: number;
    settlementPercentageFrom?: number;
    settlementPercentageTo?: number;
    auctionTenderResult?: number;
    creditorsNumberFrom?: number;
    creditorsNumberTo?: number;
    todayPublications?: boolean;
    [key: string]: any;
}

export enum PORTAL {
    WIKICASA = 0,
    COMMERCIALI,
    CASACLICK,
    BLINKS,
    WIKISERVIZI,
    APP
}

export interface SearchBeanMap extends Record<string, string> {
    priceFrom: string;
    priceTo: string;
    sqMfrom: string;
    sqMto: string;
    roomsFrom: string;
    roomsTo: string;
    bathroomsFrom: string;
    bathroomsTo: string;
    conditionType: string;
    energyClassFilter: string;
    floor: string;
    excludeAuctions: string;
    shortRentOnly: string;
    excludeBareOwnership: string;
    onlyBareOwnership: string;
    onlyPublicUser: string;
    balcony: string;
    terrace: string;
    balconyTerrace: string;
    boxCarSpot: string;
    carSpot: string;
    box: string;
    lift: string;
    privateGarden: string;
    publicGarden: string;
    cellar: string;
    airConditioning: string;
    furnished: string;
    publicTransport: string;
    school: string;
    market: string;
    hospital: string;
    pharmacy: string;
    charging: string;
    post: string;
    bank: string;
    swimmingPoolPrivate: string;
    swimmingPoolPublic: string;
    filterOrderId: string;

    auctionMinimumOfferFrom:string;
    auctionMinimumOfferTo:string;
    auctionMinimumBid:string;
    auctionCourtTypeID:string;
    auctionSaleModeID:string;
    auctionProcedureNumber:string;
    auctionReferenceYear: string;
    auctionPublicationDateFrom:string;
    auctionPublicationDateTo:string;
    auctionDateFrom:string;
    auctionDateTo:string;
}

export const searchBeanMap: SearchBeanMap = {
    priceFrom: "PrezzoMinimo",
    priceTo: "PrezzoMassimo",
    sqMfrom: "SuperficieMinima",
    sqMto: "SuperficieMassima",
    roomsFrom: "NumeroMinimoLocali",
    roomsTo: "NumeroMassimoLocali",
    bathroomsFrom: "NumeroMinimoBagni",
    bathroomsTo: "NumeroMassimoBagni",
    conditionType: "StatoImmobile",
    energyClassFilter: "ClasseEnergetica",
    floor: "Piano",
    excludeAuctions: "EscludiAste",
    shortRentOnly: "SoloAffittiBrevi",
    excludeBareOwnership: "EscludiNudaProprieta",
    onlyBareOwnership: "SoloNudaProprieta",
    onlyPublicUser: "DaPrivato",
    balcony: "Balcone",
    terrace: "Terrazzo",
    balconyTerrace: "BalconeTerrazzo",
    boxCarSpot: "BoxPostoAuto",
    carSpot: "PostoAuto",
    box: "Box",
    lift: "Ascensore",
    privateGarden: "GiardinoPrivato",
    publicGarden: "GiardinoPubblico",
    cellar: "Cantina",
    airConditioning: "AriaCondizionata",
    furnished: "Arredato",
    publicTransport: "Trasporti",
    school: "Istruzione",
    market: "Supermercati",
    hospital: "Ospedali",
    pharmacy: "Farmacie",
    charging: "Ricarica",
    post: "UfficioPostale",
    bank: "Banche",
    swimmingPoolPrivate: "PiscinaPrivata",
    swimmingPoolPublic: "PiscinaCondominiale",
    luxury: "Lusso",
    discounted: "Sconto",
    auctionMinimumOfferFrom : "OffertaMinimaAstaDa",
    auctionMinimumOfferTo : "OffertaMinimaAstaA",
    auctionMinimumBid: "RialzoMinimoAsta",
    auctionCourtTypeID: "TribunaleAsta",
    auctionSaleModeID: "ModalitaVenditaAsta",
    auctionProcedureNumber:"NumeroProceduraAsta",
    auctionProcedureTypologyID: "TipologiaProceduraAsta",
    auctionReferenceYear: "AnnoRiferimentoAsta",
    auctionPublicationDateFrom:"DataPubblicazioneAstaDa",
    auctionPublicationDateTo:"DataPubblicazioneAstaA",
    auctionDateFrom:"DataAstaDa",
    auctionDateTo:"DataAstaA",
    /* Don't consider this prop in the filters counter */
    filterOrderId: "Ordinamento",
}

//SimoR: Added for filtering inside agency details wikicasa, in another const because it breaks the buildParams of the real estate listing
export const searchBeanMapAgencyFilter: any = {
    sale: "Vendita",
    rent: "Affitto",
    auctionType: "Asta",
    bareOwnershipType: "NudaProprieta",
    listingTypologyIdList: "listingTypologyID",
    filterOrderId: "Ordinamento"
}

export interface IRealEstateSearchBeanServiceDto extends IRealEstateSearchBean {
    placeAndYearDto: {
        placeId: number,
        year: string
    }
}

declare const window: {
    _contractDefault?: number;
    _macroTypologyDefault?: number;
    _listingTypologyURLDefault?: string;
    _subscriptionTypesDefault?: number[];
} & Window


export const defaultSearchBeanValues: Partial<IRealEstateSearchBean> = {
    contractType: window._contractDefault,
    excludeAuctions: false,
    excludeBareOwnership: false,
    listingTypologyId: 0,
    listingTypologyIdList: [0],
    listingTypologyURL: window._listingTypologyURLDefault,
    macroTypologyId: window._macroTypologyDefault,
    onlyBareOwnership: false,
    onlyVisible: true,
    rent: false,
    sale: true,
    shortRentOnly: false,
    subscriptionTypes: window._subscriptionTypesDefault,
    typologyIdList: window._typologyIdListDefault,
}

export function getDefaultSearchBean(): IRealEstateSearchBean {
    return {...defaultSearchBeanValues} as IRealEstateSearchBean;
}

export const getDefaultAuctionFiltersSearchBean = ():IRealEstateSearchBean =>{
    return {
        ...getDefaultSearchBean(),
        sale:false,
        rent:false,
        auctionType:1,
        contractType:3
    }
}

export const getDefaulTypologyIdList = (): number[] => window._typologyIdListDefault;
