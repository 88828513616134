import Field from "./Field";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.it";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default class PhoneField extends Field {
    private _cleave: Cleave;

    constructor($input: JQuery, form: Form, type: string = "string") {
        super($input, form, type);

        const _defCleave = {
            phone: true,
            phoneRegionCode: "it"
        };

        this._cleave = new Cleave($input[0], _defCleave);
    }

    getValue(): Record<string, unknown> {
        return super.processValue(String(this._cleave.getRawValue()));
    }

    setValue(value: number | string): void {
        if (Number(value) === 0)
            return;

        this._cleave.setRawValue(value.toString());
        this.selector.trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this._cleave.getRawValue() ? false : super.processValid(this.getValue());
    }

    clear(): void {
        this.setValue("");
    }
}