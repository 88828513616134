import AutocompleteField from "./AutocompleteField";
import DateField from "./DateField";
import PhoneField from "./PhoneField";
import NumberField from "./NumberField";
import RadioField from "./RadioField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import TextField from "./TextField";
import EmailField from "./EmailField";
import PasswordField from "./PasswordField";
import * as Icons from "../icons";
import Field from "./Field";

const _defOptions = {
    discardEmptyValues: true,
    discardEmptyCheckbox: false
};

/**
 * @author Andrea Moraglia
 */
export default class Form {
    private readonly _selector: JQuery;
    private readonly _btn: JQuery;
    private readonly _fields: Array<Field>;
    private readonly _options: any;

    constructor($selector: JQuery, options?: any) {

        this._selector = $selector;
        this._btn = $selector.find("button[type=\"submit\"]");
        this._fields = [];

        this._options = $.extend(true, _defOptions, options);

        this.init();
    }

    init(): void {

        const self = this;

        (self._selector[0] as HTMLFormElement)?.reset();

        self._selector.attr("novalidate", "true");

        const fields = self._selector.find("input, select, textarea");

        for (let i = 0; i < fields.length; i++) {
            const $field = $(fields[i]);

            if ($field.prop("readonly") || !$field.prop("name")) continue;

            let field;
            const type = $field.data("type");

            switch ($field.prop("type")) {
                case "search":
                    field = new AutocompleteField($field, self, type);
                    break;
                case "date" :
                    field = new DateField($field, self, type);
                    break;
                case "tel":
                    field = $field.data("phone") ?
                        new PhoneField($field, self, type) :
                        new NumberField($field, self, type);
                    break;
                case "radio":
                    field = new RadioField($field, self, type);
                    break;
                case "checkbox":
                    field = new CheckboxField($field, self, type);
                    break;
                case "select-one":
                    field = new SelectField($field, self, type);
                    break;
                case "email":
                    field = new EmailField($field, self, type);
                    break;
                case "password":
                    field = new PasswordField($field, self, type);
                    break;
                case "text":
                case "textarea":
                case "hidden":
                    field = new TextField($field, self, type);
                    break;
            }

            if (field)
                this._fields.push(field);

            if ($field.prop("required"))
                $field.siblings("label").toggleClass("label-required");
        }
    }

    validate(visible: boolean = false, selector?: string): boolean {

        const self = this;

        let valid = true;

        this.getFields()
            .filter((field: Field) => (!selector || field.selector.closest(selector).length !== 0) && field.selector.closest("[data-hidden=\"true\"]").length === 0)
            .forEach((field: Field) => {
                let validField = true;

                if (!field.isValid()) {
                    valid = false;
                    validField = false;
                }

                if (field.selector.data("requires") && field.hasValue()) {
                    const requires = this.getField(field.selector.data("requires"));
                    if (!requires!.isValid() || requires!.getValue()[String(requires!.selector.attr("name")).split(".")[0]] == null) {
                        valid = false;
                        requires?.selector.addClass("has-error");
                    }
                }

                if (!validField && visible)
                    self.getSelector().find(`[name="${field.name}"]`).add(field.selector).addClass("has-error");

            });

        return valid;
    }

    getOptions(): any {
        return this._options;
    }

    getField(name: string): Field | undefined {
        const result = this.getFields().filter((field: Field) => field.name === name);

        if (result.length)
            return result[0];
    }

    getFields(): Array<Field> {
        return this._fields;
    }

    getFieldSelectors(): Array<JQuery> {
        return this.getFields().map((field: Field) => field.selector);
    }

    getFormElement(): HTMLFormElement {
        return this._selector[0] as HTMLFormElement
    }

    getValues(fields: Array<Field> = this.getFields()): any {
        return $.extend(true, {}, ...fields.filter((field: Field) =>
            field.selector.closest("[data-hidden=\"true\"]").length === 0).map((field: Field) => field.getValue()));
    }

    on(event: string, fn: (e: JQuery.Event) => unknown): void {
        this.getSelector().on(event, (e: JQuery.Event) => fn(e))
    }

    onSubmit(fn: (e: JQuery.Event) => unknown): void {
        this._selector.on("submit", (e: JQuery.Event) => {
            Icons.addSpinner(this.getButton());
            return fn(e);
        });
    }

    offSubmit(): void {
        this._selector.off("submit")
    }

    clearFieldsData(): void {
        this.getFields().forEach(field => {
            field.selector.removeClass("has-error")
            field.selector.removeData();
        })
    }

    clearSubmit(): void {
        Icons.removeSpinner(this.getButton());
    }

    submit(): void {
        this.getSelector().trigger("submit");
    }

    getSelector(): JQuery {
        return this._selector;
    }

    getButton(): JQuery {
        return this._btn;
    }

    clear(): void {
        (this.getSelector()[0] as HTMLFormElement).reset();
        // eslint-disable-next-line max-len
        this.getFields().filter((item: Field) => item.selector.prop("type") === "select-one").forEach((item: Field) => item.selector.trigger("change"));
    }

    load(values: any): void {

        const find = (obj: Record<string, any>): any => Object.keys(obj)
            .filter((key: string) => obj[key] instanceof Object)
            .map((key: string) => find(obj[key]).map((k: string) => `${key}.${k}`))
            .reduce((x: Array<string>, y: Array<string>) => x.concat(y), Object.keys(obj));

        const keys = find(values);

        keys.forEach((key: string) => {

            const field = this.getFields().filter((field: Field) => field.name === key)[0];

            if (!field)
                return;

            const arr = field.name.split(".");

            const val = arr.reduce((acc: Record<string, unknown>, elem: string) => acc[elem], values);

            field.setValue(val);
        })
    }
}