import Field from "./Field";
import Form from "./Form";

declare global {
    interface Window {
        _emailRegex: string;
    }
}

const emailRegExp = new RegExp(window._emailRegex || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

/**
 * @author Andrea Moraglia
 */
export default class EmailField extends Field {

    constructor($input: JQuery, form: Form, type: string = "string") {
        super($input, form, type);
    }

    getValue(): string {
        return super.processValue(this.selector.val());
    }

    setValue(value: string): void {
        this.selector.val(value).trigger("change");
    }

    isValid(): boolean {
        if (this.isRequired() && !this.selector.val()) return false;
        if (!this.isRequired() && !this.selector.val()) return true;

        if (!this.selector.data("regex") && !emailRegExp.test(String(this.selector.val())))
            return false;
        return super.processValid(this.getValue());
    }

    clear(): void {
        this.setValue("");
    }
}