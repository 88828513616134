import Field from "./Field";
import Autocomplete, {IAutocompleteInit} from "../autocomplete";
import Form from "./Form";

/**
 * @author Andrea Moraglia
 */
export default class AutocompleteField extends Field {

    private readonly _prop: string;
    private readonly _nameZone: string;
    private readonly _nameLocality: string;

    private readonly _autocomplete: Autocomplete;

    constructor($input: JQuery, form: Form, type: string = "number") {
        super($input, form, type);

        this._autocomplete = new Autocomplete($input, {
            clear: !!$input.data("clear"),
            appendTo: $($input.data("append-to"))
        } as IAutocompleteInit);

        $input.removeAttr("name");

        $input.data("autocomplete", this._autocomplete);

        this._prop = $input.data("get") ? $input.data("get") : "placeID";
        this._nameZone = $input.data("name-zone") ? $input.data("name-zone") : "zoneIds";
        this._nameLocality = $input.data("name-locality") ? $input.data("name-locality") : "localityIds";
    }

    getValue(): Record<string, unknown> {

        if (this._autocomplete.enableZones) {
            return super.processValue(({
                [this.name]: this._autocomplete.place ? (this._autocomplete.place as Record<string, any>)[this._prop] : undefined,
                [this._nameZone]: this._autocomplete.zones.length ? this._autocomplete.zones.map((zone: any) => zone.id) : undefined,
                [this._nameLocality]: this._autocomplete.localities.length ? this._autocomplete.localities.map((locality: any) => locality.id) : undefined
            }));
        } else {
            return super.processValue(({
                [this.name]: this._autocomplete.place ? (this._autocomplete.place as Record<string, any>)[this._prop] : undefined,
            }));
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setValue(value: any): void {
        throw new Error("Method not implemented.");
    }

    isValid(): boolean {
        return this.isRequired() && !this._autocomplete.place ? false : super.processValid(this.getValue());
    }

    clear(): void{
        return;
    }

}