import Field from "./Field";
import Cleave from "cleave.js";
import Form from "./Form";
import type {CleaveOptions} from "cleave.js/options";

/**
 * @author Andrea Moraglia
 */
export default class NumberField extends Field {

    private _cleave: Cleave;

    constructor($input: JQuery, form: Form, type: string = "number") {
        super($input, form, type);

        const _defCleave = {
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            numeralDecimalScale: 0,
            numeralIntegerScale: $input.prop("max") || 9,
            delimiter: ".",
            numeralDecimalMark: ",",
            numeralPositiveOnly: true
        } as CleaveOptions;

        this._cleave = new Cleave($input[0], _defCleave);

        $input.data("cleave", this._cleave);

        // TODO @amoraglia Fix sync with mobileForm
        /*if ($input.attr("data-from")) {
            const $target = $($input.attr("data-from") ?? "");

            $input.change(() => {
                const cleave: Cleave = $target.data("cleave");
                if (cleave && cleave.getRawValue() && this._cleave.getRawValue() < cleave.getRawValue())
                    cleave.setRawValue(this._cleave.getRawValue());
            })
        }

        if ($input.attr("data-to")) {
            const $target = $($input.attr("data-to") ?? "");

            $input.change(() => {
                const cleave: Cleave = $target.data("cleave");
                if (cleave && cleave.getRawValue() && this._cleave.getRawValue() > cleave.getRawValue())
                    cleave.setRawValue(this._cleave.getRawValue());
            })
        }*/
    }

    getValue(): Record<string, unknown> {
        return super.processValue(Number(this._cleave.getRawValue()));
    }

    setValue(value: number | string | null): void {

        if (value == null) {
            this._cleave.setRawValue("")
            this.selector.trigger("change")
            return;
        }

        value = Number(value);

        if (value === 0 && !this.selector.data("allowZero"))
            return;

        this._cleave.setRawValue(value.toString());
        this.selector.trigger("change");
    }

    isValid(): boolean {
        return this.isRequired() && !this._cleave.getRawValue() ? false : super.processValid(this.getValue());
    }

    clear(): void {
        this.setValue(null);
    }
}