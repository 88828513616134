import "bootstrap/js/dist/modal";

const regex = /modal-(sm|md|lg|xl)-none/;

const modals: Array<JQuery> = [];

let triggerBack = false;
let lock: Promise<void> | null;

function addModal(e: JQuery.EventBase): void {
    const $modal = $(e.target);

    modals.push($modal);

    $modal.css("z-index", 1050 + modals.length);

    window.history.pushState($modal.attr("id"), "", `#${$modal.attr("id")}`);
}

$(() => {
    /*const $body = $("body");

    $body.data("modal", []);

    $body.on("shown.bs.modal", (e: JQuery.EventBase) => {
        const $modal = $(e.target);

        const match = regex.exec($modal.attr("class") ?? "");

        if (match) {
            $body.addClass(`modal-${match[1]}-close`);
            $(".modal-backdrop").addClass(`d-${match[1]}-none`);
        }
    });

    $body.on("show.bs.modal", (e: JQuery.EventBase) => {
        if (lock) {
            lock.then(() => {
                addModal(e);
                lock = null;
            });
        } else {
            addModal(e);
        }
    });

    $body.on("hide.bs.modal", (e: JQuery.EventBase) => {

        lock = new Promise((resolve: () => void) => {

            const $modal = $(e.target);
            $modal.css("z-index", "");

            if (!modals.length || !modals[modals.length - 1].is($modal))
                return resolve();

            modals.pop();
            triggerBack = true;
            $(window).one("popstate", () => resolve());
            window.history.back();
        });
    });

    $(window).on("popstate", () => {

        if (triggerBack) {
            triggerBack = false;
            return;
        }

        if (!modals.length)
            return;

        const $modal = modals.pop();
        $modal?.modal("hide");
    });*/

    /*let $hash = $(window.location.hash);

        if ($hash.length && $hash.hasClass('modal')) {
            $hash.modal('show');
        }*/
});